import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { IRegistrationType } from '@app/lib/interfaces';
import { MetaDataService } from '@app/lib/metadata/metadata.service';

@Component({
  selector: 'registration-type-form',
  template: `
    <fieldset [formGroup]="form" *ngIf="form && registrationTypes">
      <div class="row">
        <div class="col form-group">
          <legend>{{ legend }}?</legend>
          <div class="custom-control custom-radio" *ngFor="let registrationType of registrationTypes">
            <input formControlName="registrationType" [value]="registrationType._id" type="radio" [attr.id]="registrationType._id" name="registrationType" class="custom-control-input">
            <label class="custom-control-label" [attr.for]="registrationType._id">{{ registrationType.title }}</label>
          </div>
        </div>
      </div>
    </fieldset>
  `
})
export class RegistrationTypeFormComponent implements OnInit, OnDestroy {

  @Input()
  form: UntypedFormGroup;

  @Input()
  registrationTypeId = null;

  @Output()
  registrationTypeSelected = new EventEmitter<string>();

  changeHandlerSubscription: Subscription;
  legend = 'Are you a ';
  registrationTypes: IRegistrationType[] = null;
  subscription: Subscription;

  constructor (
    private metaDataService: MetaDataService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.metaDataService.state$.subscribe(state => {
      this.registrationTypes = state.registrationTypes;

      if (this.form && this.registrationTypes) {
        this.registrationTypes.forEach((registrationType, index) => {
          const ordinal = index + 1;

          if (ordinal === this.registrationTypes.length) {
            this.legend += ' or ';
          }

          this.legend += registrationType.title;

          if (ordinal < (this.registrationTypes.length - 1)) {
            this.legend += ', ';
          }
        });

        this.form.addControl('registrationType', new UntypedFormControl(this.registrationTypeId));

        this.registrationTypeChangeHandler();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();

    if (this.changeHandlerSubscription) {
      this.changeHandlerSubscription.unsubscribe();
    }
  }

  registrationTypeChangeHandler(): void {
    const control = <UntypedFormControl>this.form.get('registrationType');

    this.changeHandlerSubscription = control.valueChanges.subscribe(value => {
      const registrationType = this.registrationTypes.find(rt => rt._id === value);
      this.registrationTypeSelected.emit(registrationType ? registrationType.title : '');
    });
  }
}
