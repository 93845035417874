import { Injectable } from '@angular/core';

import { MetaDataState } from '@app/lib/metadata/metadata.state';
import { Store } from '@app/lib/state/store';

@Injectable()
export class MetaDataStore extends Store<MetaDataState> {

  constructor () {
    super(new MetaDataState());
  }

}
