<div class="row">
  <div class="col col-sm-8 col-md-8 col-lg-8">
    <h1>Archived Meetings</h1>
    <activity *ngIf="loading"></activity>
    <meetings-list [meetings]="meetings | paginate: paginationConfig" *ngIf="meetings && meetings.length > 0"></meetings-list>
    <div *ngIf="!loading && loadMeetingsError" class="alert alert-danger fade show" role="alert">
      <strong>We&#8217;re sorry!</strong> Archived meetings are temporarily unavailable.
    </div>
    <h2 *ngIf="!loading && meetings && meetings.length === 0">There aren&#8217;t any archived meetings at the moment, but check back with us soon.</h2>
    <pagination [paginationConfig]="paginationConfig"></pagination>
  </div>
</div>
