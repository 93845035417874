import { Component, Input, OnChanges} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { NewlineToBreakPipe } from '@app/lib/pipes/newline-to-break.pipe';

@Component({
    selector: 'truncate-text',
    template: `
      <div [innerHTML]="currentText"></div>
      <a class="d-inline-block my-sm-2 mt-3 mb-2" *ngIf="text.length > maxLength" href="#" [class.hidden]="hideToggle" (click)="toggleView($event)">Read {{ isCollapsed? 'more' : 'less' }}</a>
    `
})

export class TruncateTextComponent implements OnChanges {

    @Input()
    text = '';

    @Input()
    maxLength = 150;

    currentText: string;
    hideToggle = true;
    isCollapsed = true;
    newlineToBreakPipe: NewlineToBreakPipe;

    constructor(
      private domSanitizer: DomSanitizer,
    ) {
      this.newlineToBreakPipe = new NewlineToBreakPipe(this.domSanitizer);
    }

    toggleView(event: Event) {
      event.preventDefault();
      this.isCollapsed = !this.isCollapsed;
      this.determineView();
    }

    determineView() {
      if (this.text.length <= this.maxLength) {
          this.currentText = this.text;
          this.isCollapsed = false;
          this.hideToggle = true;

          return;
      }

      this.hideToggle = false;

      if (this.isCollapsed) {
        this.currentText = this.text.substring(0, this.maxLength) + '...';
      }
      else {
        this.currentText = this.newlineToBreakPipe.transform(this.text);
      }
    }

    ngOnChanges() {
      this.determineView();
    }
}
