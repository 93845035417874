import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'pagination',
  styleUrls: [
    './pagination.component.scss',
  ],
  template: `
    <pagination-template class="d-flex flex-row align-items-center pagination-container" #paginationApi="paginationApi" (pageChange)="onPageChange($event)">
      <span class="current-page" *ngIf="paginationApi.pages.length > 0">Page {{ paginationConfig.currentPage }} of {{ paginationApi.pages.length }}</span>
      <form class="form-inline" >
        <go-to-page class="go-to-page" [paginationApi]="paginationApi"></go-to-page>
        <per-page class="per-page" [paginationConfig]="paginationConfig"></per-page>
      </form>
      <pagination-links [paginationApi]="paginationApi" class="pagination-links" *ngIf="paginationApi.pages.length > 1"></pagination-links>
    </pagination-template>
  `,
})
export class PaginationComponent {

  @Input()
  paginationConfig: PaginationInstance;

  onPageChange(pageNumber: number) {
    this.paginationConfig.currentPage = pageNumber;
  }

}
