import { COST, PRO_RATED_COST } from '@app/lib/data/constants';
import { IMember, IMemberPost } from '@app/lib/interfaces';

export function sum(amounts: number[]): number {
  return amounts.reduce((accumulator, value) => accumulator + value);
}

export function isEmpty(object: Object): boolean {
  return Object.keys(object).map(key => object[key]).every(x => (x === null || x === '' || x === undefined));
}

export function getMembershipYear(member?: IMember | IMemberPost, year?: number): number {
  // When creating or renweing a Membership, what year is the membership for?

  // If the member is new, or their `yearValid` is less than this year, the year is the **current** year.
  // Otherwise, if it's an existing member and they are renewing in the same year, the year is the **next** year.

  const thisYear = new Date().getFullYear();
  const yearValue = member ? member.membership.yearValid : year;

  if (yearValue) {
    return yearValue === thisYear ? thisYear + 1 : thisYear;
  }

  return thisYear;
}

/**
 * If the membership year valid is equal to the next year, the full membership
 * cost applies. Otherwise, the prorated cost applies.
 */
export function getCostKey(yearValid?: number): string {
  if (yearValid && yearValid === new Date().getFullYear() + 1) {
    return COST;
  }

  return PRO_RATED_COST;
}

export function groupObjectsBy(objects: object[], key: string) {
  return objects.reduce(function(previousValue, currentValue) {
    (previousValue[currentValue[key]] = previousValue[currentValue[key]] || []).push(currentValue);
    return previousValue;
  }, {});
}

export function replaceObjectInArray(objects: object[], index: number, newObject: object): object[] {
  return [...objects.slice(0, index), newObject, ...objects.slice(index + 1)];
}

export function isEmptyObject(obj: object): boolean {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
