<h3>Speaker</h3>
<ul class="list-unstyled">
  <li>
    <button *ngIf="speaker.bio" type="button" class="btn btn-link btn-collapse btn-bio" data-toggle="modal" data-target="#speakerModal">
      <strong>{{ speaker.name.first }} {{ speaker.name.last }}</strong>
    </button>
    <ng-container *ngIf="!speaker.bio"><strong>{{ speaker.name.first }} {{ speaker.name.last }}</strong></ng-container>
    <ng-container *ngIf="speaker.jobTitle || speaker.company">
      <small class="d-block"><ng-container *ngIf="speaker.jobTitle">{{ speaker.jobTitle }}</ng-container><ng-container *ngIf="speaker.jobTitle && speaker.company"> at </ng-container><ng-container *ngIf="speaker.company">{{ speaker.company }}</ng-container></small>
    </ng-container>
  </li>
  <li *ngIf="speaker.website">
    <a [attr.href]="speaker.website" target="_blank">{{ speaker.website }}</a>
  </li>
</ul>

<div class="modal fade" id="speakerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h1>{{ speaker.name.first }} {{ speaker.name.last }}</h1>
        <p [innerHtml]="speaker.bio | newlineToBreak"></p>
      </div>
    </div>
  </div>
</div>
