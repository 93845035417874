import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, timer, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { RegistrationsService } from '@app/registrations/registrations.service';

@Injectable()
export class RegistrationValidators {

  constructor (
    private registrationsService: RegistrationsService,
  ) {}

  checkDuplicateRegistrationAndMembership(email?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value === null || control.value.length === 0) {
        return of(null);
      }
      else if (email && email === control.value) {
        return of(null);
      }
      else {
        return timer(500).pipe(
          switchMap(() => {
            return this.registrationsService
                        .checkRegistrations(control.value)
                        .pipe(
                          map(registrationCheck => {
                            if (registrationCheck.registration) {
                              return { duplicateRegistration: { value: control.value } };
                            }

                            if (registrationCheck.member) {
                              if (registrationCheck.member.membership.yearValid < new Date().getFullYear()) {
                                return { expired: { value: control.value } };
                              }
                            }

                            return null;
                          })
                        );
          })
        );
      }
    };
  }

}
