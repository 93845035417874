import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'number-of-people',
  template: `
    <fieldset [formGroup]="form">
      <div class="row">
        <div class="col form-group">
          <legend>{{ subhead }}</legend>
          <div class="custom-control custom-radio">
            <input formControlName="numberOfPeople" [value]="1" type="radio" id="onePerson" name="numberOfPeople" class="custom-control-input">
            <label class="custom-control-label" for="onePerson">Just me</label>
          </div>
          <div class="custom-control custom-radio">
            <input formControlName="numberOfPeople" [value]="2" type="radio" id="multiplePeople" name="numberOfPeople" class="custom-control-input">
            <label class="custom-control-label" for="multiplePeople">Two or more people</label>
          </div>
        </div>
      </div>
    </fieldset>
  `
})
export class NumberOfPeopleComponent implements OnInit, OnDestroy {

  @Input()
  numberOfPeople: number = null;

  @Input()
  subhead: string;

  @Output()
  numberOfPeopleSelected = new EventEmitter<number>();

  form: UntypedFormGroup;
  subscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      numberOfPeople: [
        this.numberOfPeople ? this.numberOfPeople : null,
      ],
    });

    this.numberOfPeopleChangeHandler();
  }

  numberOfPeopleChangeHandler(): void {
    const control = <UntypedFormControl>this.form.get('numberOfPeople');

    this.subscription = control.valueChanges.subscribe(value => {
      this.numberOfPeopleSelected.emit(parseInt(value));
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
