import { Component } from '@angular/core';

@Component({
  template: `
    <div class="row">
      <div class="col">
        <h1>Sorry, we couldn&#8217;t find that page&hellip;</h1>
      </div>
    </div>
  `
})
export class NotFoundComponent {}
