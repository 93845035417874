<fieldset [formGroup]="form">
  <div class="row">
    <div class="col form-group member-options">
      <legend>Tulsa HRC Committees and Member Directory</legend>
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6 form-group">
          <h4 class="custom-control-header">Interested in serving on a committee?</h4>
          <div class="custom-control custom-radio">
            <input formControlName="committees" [value]="true" type="radio" id="committeesTrue" name="committees" class="custom-control-input">
            <label class="custom-control-label" for="committeesTrue">Yes, I would like more information</label>
          </div>
          <div class="custom-control custom-radio">
            <input formControlName="committees" [value]="false" type="radio" id="committeesFalse" name="committees" class="custom-control-input">
            <label class="custom-control-label" for="committeesFalse">Not at this time</label>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 form-group">
          <h4 class="custom-control-header">Would you like to display your name in the directory?</h4>
          <div class="custom-control custom-radio">
            <input formControlName="showInDirectory" [value]="true" type="radio" id="directoryTrue" name="showInDirectory" class="custom-control-input">
            <label class="custom-control-label" for="directoryTrue">Yes</label>
          </div>
          <div class="custom-control custom-radio">
            <input formControlName="showInDirectory" [value]="false" type="radio" id="directoryFalse" name="showInDirectory" class="custom-control-input">
            <label class="custom-control-label" for="directoryFalse">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>
