<h3>Time &amp; Place</h3>
<ul class="list-unstyled">
  <li>
    <time *ngIf="meeting.formattedTime" [innerHTML]="meeting.formattedTime | mobileDateTime"></time>
    <address *ngIf="meeting.location">
      <a [attr.href]="'https://maps.google.com/?q=' + meeting.location.address.street + ',' + meeting.location.address.city + ',' + meeting.location.address.state + ',' + meeting.location.address.zip" target="_blank"><strong>{{ meeting.location.name }}</strong><br />
      {{ meeting.location.address.street }}<br />
      {{ meeting.location.address.city }}, {{ meeting.location.address.state }} {{ meeting.location.address.zip }}</a>
    </address>
    <img *ngIf="meeting.virtual" [ngClass]="{ 'mb-2': true, 'mt-2': meeting.location && meeting.virtual }" src="assets/images/large-zoom.png" alt="Zoom">
  </li>
  <li *ngIf="meeting.location?.phone">
    <a [attr.href]="'tel:' + meeting.location.phone">{{ meeting.location.phone }}</a>
  </li>
  <li *ngIf="meeting.location?.web">
    <a [attr.href]="meeting.location.web" target="_blank">{{ meeting.location.web }}</a>
  </li>
</ul>
