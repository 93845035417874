import { Component, Input } from '@angular/core';


@Component({
  selector: 'go-to-page',
  template: `
    <div class="form-group">
      <label>Go to Page</label>
      <select class="form-control" [ngModel]="paginationApi.getCurrent()" (ngModelChange)="paginationApi.setCurrent($event)">
        <option *ngFor="let page of paginationApi.pages" [value]="page.value">{{ page.value }}</option>
      </select>
    </div>
  `
})
export class GoToPageComponent {

  @Input()
  paginationApi: any;

}
