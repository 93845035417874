import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {

  transform(path: string): string {
    let extension = '';
    let iconCss;

    if (path) {
      const pathArray = path.split('.');
      extension = pathArray[pathArray.length - 1];
    }

    switch (extension) {
      case 'pptx':
      case 'ppt': {
        iconCss = 'fa-file-powerpoint-o';
        break;
      }

      case 'docx':
      case 'doc': {
        iconCss = 'fa-file-word-o';
        break;
      }

      case 'xlsx':
      case 'xls': {
        iconCss = 'fa-file-excel-o';
        break;
      }

      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'gif': {
        iconCss = 'fa-file-image-o';
        break;
      }

      case 'mpeg':
      case 'mov': {
        iconCss = 'fa-file-movie-o';
        break;
      }

      case 'wav':
      case 'mp3': {
        iconCss = 'fa-file-audio-o';
        break;
      }

      case 'gzip':
      case 'zip':
      case 'tar': {
        iconCss = 'fa-file-archive-o';
        break;
      }

      case 'txt': {
        iconCss = 'fa-file-text-o';
        break;
      }

      case 'pdf': {
        iconCss = 'fa-file-pdf-o';
        break;
      }

      default: {
        iconCss = 'fa-file-o';
      }
    }

    return iconCss;
  }
}
