import { Injectable } from '@angular/core';

@Injectable()
export class UserAgentService {
  isMobile: boolean;
  isPhone: boolean;

  constructor() {
    this.isMobile = navigator.userAgent.match(/(iPad|iPhone|iPod|Android|Mobile)/g) ? true : false;
    this.isPhone = navigator.userAgent.match(/(iPhone|Android)/g) ? true : false;
  }
}
