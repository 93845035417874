import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { getEnvironmentValues } from '@app/environments/utils';
import { IInvoice } from '@app/lib/interfaces';

@Injectable()
export class InvoicesService {

  baseUrl = `${ getEnvironmentValues().api.url }/invoices`;

  constructor (
    private httpClient: HttpClient,
  ) {}

  async createInvoice(invoiceData: IInvoice): Promise<IInvoice> {
    return this.httpClient
                .post<IInvoice>(this.baseUrl, invoiceData)
                .toPromise()
                .then(invoice => invoice);
  }

}
