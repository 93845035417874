import { Injectable } from '@angular/core';

import { AuthState } from '@app/auth/auth.state';
import { Store } from '@app/lib/state/store';

@Injectable()
export class AuthStore extends Store<AuthState> {

  constructor () {
    super(new AuthState());
  }

}
