import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { shim } from 'promise.prototype.finally';

import { AppModule } from 'app/app.module';
import { environment } from '@app/environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    shim();
  })
  .catch(err => console.log(err));
