import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, timer, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { IExistsResponse } from '@app/lib/interfaces';
import { MembershipsService } from '@app/memberships/memberships.service';

@Injectable()
export class MembershipsValidators {

  currentYear: number = new Date().getFullYear();

  constructor (
    private membershipsService: MembershipsService,
  ) {}

  checkEmailExist(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value === null || control.value.length === 0) {
        return of(null);
      }
      else {
        return timer(500).pipe(
          switchMap(() => {
            return this.membershipsService.lookupMemberEmail(control.value).pipe(
              map((response: IExistsResponse) => {
                return response.exists ? { emailExists: { value: control.value } } : null;
              }),
            );
          })
        );
      }
    };
  }

  checkMembershipDoesNotExist(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value === null || control.value.length === 0) {
        return of(null);
      }
      else {
        return timer(500).pipe(
          switchMap(() => {
            return this.membershipsService.lookupMember(control.value).pipe(
              map(member => {
                if (member) {
                  return { existingMember: { value: control.value } };
                }

                return null;
              })
            );
          })
        );
      }
    };
  }

  checkMembershipExists(checkRenewal: boolean = true): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value === null || control.value.length === 0) {
        return of(null);
      }
      else {
        return timer(500).pipe(
          switchMap(() => {
            return this.membershipsService.lookupMember(control.value).pipe(
              map(member => {
                if (!member) {
                  return { noMembership: { value: control.value } };
                }

                if (checkRenewal) {
                  if (member.membership.yearValid === this.currentYear + 1) {
                    return { alreadyRenewed: { value: control.value } };
                  }
                }

                return null;
              })
            );
          })
        );
      }
    };
  }

}
