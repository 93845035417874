import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IMeeting, IMembership, IRegistrationType } from '@app/lib/interfaces';
import { MetaDataService } from '@app/lib/metadata/metadata.service';

@Component({
  selector: 'registration-fees',
  styleUrls: [
    './registration-fees.component.scss',
  ],
  templateUrl: './registration-fees.component.html',
})
export class RegistrationFeesComponent implements OnInit, OnDestroy {

  @Input()
  meeting: IMeeting = null;

  memberships: IMembership[] = null;
  metaDataSubscription: Subscription;
  registrationTypes: IRegistrationType[] = null;

  constructor (
    private metaDataService: MetaDataService,
  ) {}

  ngOnInit(): void {
    this.metaDataSubscription = this.metaDataService.state$.subscribe(metaData => {
      this.memberships = metaData.memberships;
      this.registrationTypes = metaData.registrationTypes;
    });
  }

  ngOnDestroy(): void {
    this.metaDataSubscription.unsubscribe();
  }

}
