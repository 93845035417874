<div class="row">
  <header class="header-main col-sm-12 col-lg-4 col-xl-3">
    <div class="row">
      <div class="col-10 col-sm-4 col-md-4 col-lg-12">
        <a href="#" routerLink="/">
          <img src="/assets/images/logo-w-tag.svg" alt="Tulsa HRC" />
        </a>
        <tag-line [visible]="taglineVisible" class="d-none d-lg-block"></tag-line>
        <nav-menu class="main-nav d-none d-lg-block"></nav-menu>
        <app-footer class="d-none d-lg-block"></app-footer>
      </div>
      <div class="col-2 col-sm-8 col-md-8 d-lg-none text-right">
        <button type="button" id="menu-closed" data-toggle="modal" data-target="#menuModal" role="button" aria-label="Navigation Menu"></button>
      </div>
    </div>
  </header>
  <section class="main-section col-sm-12 col-lg-8 col-xl-9">
    <router-outlet></router-outlet>
    <app-footer class="footer-mobile d-block d-sm-none"></app-footer>
  </section>
</div>

<div class="modal" id="menuModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <nav-menu></nav-menu>
      </div>
    </div>
  </div>
</div>
