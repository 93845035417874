import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { getEnvironmentValues } from '@app/environments/utils';
import { INews } from '@app/lib/interfaces';
import { NewsState } from './news.state';
import { NewsStore } from './news.store';

@Injectable()
export class NewsService {

  baseUrl = `${ getEnvironmentValues().api.url }/news`;
  state$: Observable<NewsState>;

  constructor (
    private httpClient: HttpClient,
    private store: NewsStore,
  ) {
    this.state$ = this.store.state$;
  }

  initialize(): void {
    this.httpClient.get<INews[]>(this.baseUrl).pipe(take(1)).subscribe(news => {
      this.setState({
        latestNews: news[0],
        news,
      });
    });
  }

  getState(): NewsState {
    return this.store.state;
  }

  setState(values: NewsState): void {
    this.store.setState(values);
  }

}
