<div class="mb-3">
  <time [innerHTML]="meeting.formattedTime | mobileDateTime"></time>
  <div *ngIf="meeting.location && meeting.virtual">Attend in person or via <img src="assets/images/zoom.png" alt="Zoom"></div>
  <div *ngIf="!meeting.location && meeting.virtual">Attend via <img src="assets/images/zoom.png" alt="Zoom"></div>
</div>
<h2 class="header"><a [routerLink]="'/meetings/' + meeting.slug">{{ meeting.title }}</a></h2>
<p *ngIf="meeting.description">
  <truncate-text [text]="meeting.description"></truncate-text>
</p>
<unconfirmed *ngIf="!meeting.hasTranspired && !meeting.isConfirmed"></unconfirmed>
<register-button [meeting]="meeting"></register-button>
