import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CareersState } from '@app/careers/careers.state';
import { CareersStore } from '@app/careers/careers.store';
import { getEnvironmentValues } from '@app/environments/utils';
import { IJobPosting } from '@app/lib/interfaces';

@Injectable()
export class CareersService {

  baseUrl = `${ getEnvironmentValues().api.url }/job-postings`;
  state$: Observable<CareersState>;

  constructor (
    private httpClient: HttpClient,
    private store: CareersStore,
  ) {
    this.state$ = this.store.state$;
  }

  async getJobPostings(): Promise<IJobPosting[]> {
    return this.httpClient
                .get<IJobPosting[]>(this.baseUrl)
                .toPromise()
                .then(jobPostings => {
                  this.store.setState({ jobPostings });
                  return jobPostings;
                });
  }

  async createJobPosting(jobPostingData: IJobPosting): Promise<void> {
    return this.httpClient
                .post<IJobPosting>(this.baseUrl, jobPostingData)
                .toPromise()
                .then(jobPosting => {
                  this.setState({ contactFirstName: jobPosting.firstName });
                });
  }

  getJobPosting(_id: string): IJobPosting {
    const jobPosting = this.store.state.jobPostings.find(obj => obj._id === _id);
    this.setState({ selectedJobPosting: jobPosting });
    return jobPosting;
  }

  async getJobPostingBySlug(slug: string): Promise<void> {
    await this.getJobPostings();
    const jobPosting = this.store.state.jobPostings.find(obj => obj.slug === slug);
    this.setState({ selectedJobPosting: jobPosting });
  }

  setState(values: CareersState): void {
    this.store.setState(values);
  }

}
