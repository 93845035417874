import { Component, ElementRef, Input, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'form-errors',
  template: `
  <ul *ngIf="errorMessage" class="error-list">
    <li><i class="fa fa-exclamation-circle"></i> <span>{{ errorMessage }}</span></li>
  </ul>
  `,
})
export class FormErrorsComponent implements AfterViewInit {

  @Input()
  validationMessages: object;

  @Input()
  control: UntypedFormControl;

  @Input()
  validationMessagePath: string;

  @Input()
  styledElementCssClass = 'has-error';

  @Input()
  styledElementCssSelector = '.form-group:first';

  @Input()
  method = 'parents';

  styledElement: any;

  constructor (
    private elementRef: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    this.styledElement = $(this.elementRef.nativeElement)[this.method](`${this.styledElementCssSelector}`);
  }

  get errorMessage(): string {
    let error = '';

    // Only display error if control has been changed and isn't valid
    if (this.control && this.styledElement) {
      if (this.control.dirty) {
        if (this.control.valid) {
          this.styledElement.removeClass(`${this.styledElementCssClass}`);
        }
        else {
          if (this.control && this.control.errors) {
            const errorKeys = Object.keys(this.control.errors);

            for (let i = 0; i < errorKeys.length; i++) {
              error = this.validationMessages[`${this.validationMessagePath}.${errorKeys[i]}`];
              break;
            }

            // Add/Remove css class to styled element.
            this.styledElement.addClass(`${this.styledElementCssClass}`);
          }
        }
      }
      else {
        this.styledElement.removeClass(`${this.styledElementCssClass}`);
      }
    }

    return error;
  }

}
