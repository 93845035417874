import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

import { flatten } from 'flat';
import { regexPatterns } from '@app/lib/regex-patterns';

@Component({
  selector: 'registered-by-form',
  template: `
    <ng-container [formGroup]="form">
      <fieldset class="actions" formGroupName="registeredBy">
        <legend>{{ legend }}</legend>
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-4 form-group">
            <label for="registeredByFirstName">First Name</label>
            <input class="form-control form-control-lg" id="registeredByFirstName" formControlName="firstName" type="text" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.registeredBy['controls'].firstName" [validationMessagePath]="'registeredBy.firstName'"></form-errors>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 form-group">
            <label for="registeredByLastName">Last Name</label>
            <input class="form-control form-control-lg" id="registeredByLastName" formControlName="lastName" type="text" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.registeredBy['controls'].lastName" [validationMessagePath]="'registeredBy.lastName'"></form-errors>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-4 form-group">
            <label for="registeredByEmail">Email</label>
            <input class="form-control form-control-lg" id="registeredByEmail" formControlName="email" type="email" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.registeredBy['controls'].email" [validationMessagePath]="'registeredBy.email'"></form-errors>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 form-group">
            <label for="registeredByPhone">Phone</label>
            <input mask="000-000-0000" [showMaskTyped]="true" class="form-control form-control-lg" id="registeredByPhone" formControlName="phone" type="tel" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.registeredBy['controls'].phone" [validationMessagePath]="'registeredBy.phone'"></form-errors>
          </div>
        </div>
      </fieldset>
    </ng-container>
  `,
})
export class RegisteredByFormComponent implements OnInit {

  @Input()
  form: UntypedFormGroup = null;

  @Input()
  legend = 'Registration Contact';

  @Input()
  validationMessages: object = {};

  constructor (
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    if (this.form) {
      this.form.addControl('registeredBy', this.formBuilder.group({
        firstName: [
          '', [
            Validators.required,
          ]
        ],
        lastName: [
          '', [
            Validators.required,
          ]
        ],
        email: [
          '', [
            Validators.required, Validators.email,
          ]
        ],
        phone: [
          '', [
            Validators.required,
          ]
        ]
      }));
    }

    Object.assign(this.validationMessages, flatten({
      registeredBy: {
        firstName: {
          required: 'First Name is required',
          pattern: 'This value is not accepted',
        },
        lastName: {
          required: 'Last Name is required',
          pattern: 'This value is not accepted',
        },
        email: {
          required: 'Email is required',
          email: 'Please enter a valid email',
        },
        phone: {
          required: 'Phone is required',
          pattern: '000-000-0000 format please',
        },
      }
    }));
  }

}
