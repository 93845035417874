<fieldset [formGroup]="form">
  <legend>Great! Let&#8217;s get you registered.</legend>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="registrantEmail">What&#8217;s your email address?</label>
        <div class="row">
          <div class="col-sm-4 col-md-6">
            <input #registrantEmail class="form-control form-control-lg" id="registrantEmail" formControlName="registrantEmail" type="email" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.registrantEmail" [validationMessagePath]="'registrantEmail'"></form-errors>

            <input type="text" formControlName="contactMethod" style="display: none;" autocomplete="off" />
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="form.controls.contactMethod.hasError('pattern') || form.controls.contactMethod.hasError('required')">
              <strong>Automated form submissions are not allowed</strong>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="error">
          <div class="col-sm-9 col-md-6">
            <div class="alert alert-warning alert-dismissible fade show" role="alert">
              <ng-container *ngIf="form.controls.registrantEmail.hasError('duplicateRegistration')">
                <strong>You&#8217;re already registered!</strong> Please check your email for your registration details, or we can <a class="alert-link" type="button" (click)="resendEmail($event)">re-send your registration email</a>
              </ng-container>

              <ng-container *ngIf="form.controls.registrantEmail.hasError('expired')">
                <strong>We&#8217;re sorry, your membership has expired.</strong> Please <a class="alert-link" routerLink="/memberships/renew">renew your membership</a> or <a class="alert-link" (click)="goToGuestRegistration($event)">register as a guest</a>.
              </ng-container>

              <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cancel()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>

        <div class="alert alert-info alert-dismissible fade show" role="alert" *ngIf="emailResent">
          <strong>Registration email re-sent.</strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

      </div>
    </div>
  </div>
  <div *ngIf="meeting.location && meeting.virtual" class="row">
    <div class="col">
      <div class="form-group">
        <label for="virtual">Will you be attending in person or virtually?</label>
        <div class="mt-2">
          <div class="custom-control custom-radio">
            <input type="radio" id="virtual-false" formControlName="virtual" class="custom-control-input" [value]="false">
            <label class="custom-control-label" for="virtual-false">In Person</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" id="virtual-true" formControlName="virtual" class="custom-control-input" [value]="true">
            <label class="custom-control-label" for="virtual-true">Virtual</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>
<fieldset>
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-primary btn-lg" (click)="processRegistrations()" [disabled]="!form.valid">
        <ng-container *ngIf="!processing">Next</ng-container>
        <ng-container *ngIf="processing"><i class="fa fa-spinner fa-pulse fa-fw"></i> Processing</ng-container>
      </button>
      <button type="button" class="btn btn-link btn-lg" (click)="cancel()">Cancel</button>
    </div>
  </div>
</fieldset>
