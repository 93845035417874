import { Component, Input } from '@angular/core';

import { ISpeaker } from '@app/lib/interfaces';

@Component({
  selector: 'speaker',
  styleUrls: [
    './speaker.component.scss',
  ],
  templateUrl: './speaker.component.html',
})
export class SpeakerComponent {

  @Input()
  speaker: ISpeaker;
}
