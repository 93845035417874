import { Injectable } from '@angular/core';

import { MeetingsState } from '@app/meetings/meetings.state';
import { Store } from '@app/lib/state/store';

@Injectable()
export class MeetingsStore extends Store<MeetingsState> {

  constructor () {
    super(new MeetingsState());
  }
}
