import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'newlineToBreak'
})
export class NewlineToBreakPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) {}

  transform(value: string = ''): any {
    let output;

    if (value) {
      output = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
      output = this.sanitizer.bypassSecurityTrustHtml(output);
    }

    return output ? output : value;
  }
}
