import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { getEnvironmentValues } from '@app/environments/utils';
import { ICreateUpdateMembersPostData, IExistsResponse, IMember, IMemberLookup } from '@app/lib/interfaces';
import { MembershipsState } from '@app/memberships/memberships.state';
import { MembershipsStore } from '@app/memberships/memberships.store';


@Injectable()
export class MembershipsService {

  baseUrl = `${ getEnvironmentValues().api.url }/members`;
  state$: Observable<MembershipsState>;

  constructor (
    private httpClient: HttpClient,
    private store: MembershipsStore
  ) {
    this.state$ = this.store.state$;
  }

  async createOrUpdateMembers(createUpdateMembersPostData: ICreateUpdateMembersPostData): Promise<IMember[]> {
    // remove extraneous data used for display from POST data
    createUpdateMembersPostData.memberPostsData.forEach(memberPost => {
      if (memberPost._id === null) {
        delete memberPost._id;
      }

      if (memberPost.membership.yearValid) {
        delete memberPost.membership.yearValid;
      }
    });

    const paid = createUpdateMembersPostData.stripeChargeData !== undefined;
    const invoiced = createUpdateMembersPostData.invoiceData !== undefined;

    this.store.setState({ paid, invoiced, });

    return this.httpClient
                .post<IMember[]>(this.baseUrl, createUpdateMembersPostData)
                .toPromise();
  }

  lookupMember(email: string): Observable<IMember> {
    return this.httpClient.get<IMember>(`${ this.baseUrl }/lookupMember?email=${ email }`);
  }

  lookupMemberEmail(email: string): Observable<IExistsResponse> {
    return this.httpClient.get<IExistsResponse>(`${ this.baseUrl }/lookupMemberEmail?email=${ email }`);
  }

  async lookupMembers(emails: string | string[]): Promise<IMemberLookup[]> {
    const emailAddresses = typeof emails === 'string' ? [emails] : emails;

    return this.httpClient
                .get<IMemberLookup[]>(`${ this.baseUrl }/lookup?emails=${ emailAddresses.join(',') }`)
                .toPromise()
                .then(memberLookups => {
                  this.store.setState({ memberLookups });

                  return memberLookups;
                });
  }

  sendPendingEmails(isRenewal = false): Promise<void> {
    return this.httpClient
                .post<void>(`${ this.baseUrl }/send-pending-email${ isRenewal ? '?isRenewal=true' : '' }`, { members: this.store.state.memberPostsData })
                .toPromise();
  }

  getState(): MembershipsState {
    return this.store.state;
  }

  setState(values: MembershipsState): void {
    this.store.setState(values);
  }

  clearState(): void {
    this.store.clearState();
  }

}
