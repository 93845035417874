<div class="row">
  <div class="col" *ngIf="registrations">
    <h1>Attendees</h1>
    <p *ngIf="notAllHaveMemberships">We couldn&#8217;t find a membership for all members of your party. We&#8217;ll need information for them to get your registration finalized.</p>
    <form enctype="application/x-www-form-urlencoded" role="form">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6">
          <table class="table patrons">
            <tbody>
              <tr *ngFor="let registration of registrations; let i = index">
                <th>
                  <ng-container *ngIf="registration.member !== null">
                    <span class="h2">{{ registration.member.firstName }} {{ registration.member.lastName }}</span>
                    <span class="h6 membership-type">{{ registration.member.membership.title }} Member</span>
                  </ng-container>
                  <ng-container *ngIf="!registration.member && !registration.registrationTypeObject">
                    <span class="h2">Attendee {{ i + 1 }}</span>
                    <span class="h6 membership-type no-membership">No Membership Found</span>
                  </ng-container>
                  <ng-container *ngIf="registration.registrationTypeObject">
                    <span class="h2">{{ registration.firstName }} {{ registration.lastName }}</span>
                    <span class="h6 registration-type">{{ registration.registrationTypeObject.name }}</span>
                  </ng-container>

                  <span class="h6 email">{{ registration.email }}</span>
                </th>
                <td class="cost">
                  <small class="d-block text-muted" *ngIf="!meeting.noCost && meeting.additionalCost > 0 && ((registration.member && registration.member.membership.costPerMeeting > 0) || registration.registrationTypeObject)">Registration Fee</small>
                  <span class="h2">
                    <ng-container *ngIf="registration.member">
                      <sup>$</sup>{{ (meeting.noCost || registration.virtual) ? '0.00' : registration.member.membership.costPerMeeting.toFixed(2) }}
                    </ng-container>
                    <ng-container *ngIf="registration.registrationTypeObject">
                      <sup>$</sup>{{ (meeting.noCost || registration.virtual) ? '0.00' : registration.registrationTypeObject.costPerMeeting.toFixed(2) }}
                    </ng-container>
                  </span>
                  <ng-container *ngIf="meeting.additionalCost > 0">
                    <small class="d-block text-muted">Special Event Fee</small>
                    <span class="h2"><sup>$</sup>{{ meeting.additionalCost.toFixed(2) }}</span>
                  </ng-container>

                  <button type="button" *ngIf="!registration.member" [ngClass]="{ 'btn': true, 'btn-secondary': !registration.registrationTypeObject, 'btn-link': registration.registrationTypeObject }" (click)="selectRegistration(i)" data-toggle="modal" data-target="#attendeeDetailsModal">
                    <ng-container *ngIf="!registration.registrationTypeObject">Enter</ng-container>
                    <ng-container *ngIf="registration.registrationTypeObject"><i class="fa fa-pencil-square"></i> Edit</ng-container> Details
                  </button>

                  <button type="button" *ngIf="registrations.length > 2" class="btn btn-link remove" (click)="selectRegistration(i)" data-toggle="modal" data-target="#removeAttendeeModal">
                    <i class="fa fa-times-circle"></i> Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <fieldset class="actions">
        <button type="button" class="btn btn-primary btn-lg" [disabled]="!allRegistrationsValid" (click)="onNext()">Next</button>
        <button type="button" class="btn btn-link btn-lg" (click)="cancel()">Cancel</button>
      </fieldset>
    </form>
  </div>
</div>

<div class="modal fade modal-danger" id="removeAttendeeModal" tabindex="-1" role="dialog" aria-labelledby="removeAttendeeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="alert">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="removeAttendeeModalLabel">Are you sure you want to remove this attendee?</h2>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="removeRegistration()" data-dismiss="modal" aria-label="Remove Attendee">Yes</button>
        <button type="button" class="btn btn-link btn-lg" data-dismiss="modal" aria-label="Close">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade modal-primary" id="attendeeDetailsModal" tabindex="-1" role="dialog" aria-labelledby="attendeeDetailsModalLabel" aria-hidden="true" *ngIf="selectedRegistration">
  <div class="modal-dialog modal-lg" role="alert">
    <div class="modal-content">
      <form enctype="application/x-www-form-urlencoded" role="form">
        <div class="modal-header">
          <h2 id="attendeeDetailsModalLabel">Attendee {{ attendeeNumber }} Details</h2>
        </div>
        <div class="modal-body">
          <guest-registration-form #guestRegistrationForm [form]="form" [validationMessages]="validationMessages" [registrationData]="selectedRegistration" [personInfoCols]="4" [jobTitleCols]="6" (registrationTypeWasSelected)="onRegistrationTypeSelected($event)"></guest-registration-form>
        </div>
        <div class="modal-footer" *ngIf="registrationTypeSelected">
          <button type="button" class="btn btn-primary btn-lg" (click)="updateRegistration()" data-dismiss="modal" aria-label="Update Attendee" [disabled]="!form.valid">Save</button>
          <button type="button" class="btn btn-link btn-lg" data-dismiss="modal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
