import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IMeeting, IMemberLookup, IRegistration } from '@app/lib/interfaces';
import { RegistrationsService } from '@app/registrations/registrations.service';

@Component({
  templateUrl: './registration-complete.component.html',
})
export class RegistrationCompleteComponent implements OnInit, OnDestroy {

  amountPaid: number = null;
  fullyPaidMember = false;
  invoiced = false;
  meeting: IMeeting = null;
  memberLookups: IMemberLookup[] = null;
  paid = false;
  registrations: IRegistration[] = null;
  registrationEmail: string = null;
  registrantFirstName: string = null;
  subscription: Subscription;

  constructor (
    private registrationsService: RegistrationsService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.registrationsService.state$.subscribe(state => {
      this.invoiced = state.invoiced;
      this.meeting = state.selectedMeeting;
      this.memberLookups = state.memberLookups;
      this.paid = state.paid;
      this.registrations = state.registrations;

      if (this.registrations) {
        if (this.registrations.length === 1) {
          const registration = this.registrations[0];

          this.amountPaid = registration.amountPaid;
          this.registrationEmail = registration.email;
          this.registrantFirstName = registration.firstName;

          // see if member is fully paid
          const member = this.memberLookups && this.memberLookups[0].member;

          if (!this.paid && member) {
            this.fullyPaidMember = member.membership.costPerMeeting === 0;
          }
        }
        else {
          this.amountPaid = this.registrations.map(registration => registration.amountDue).reduce((previousValue: number, currentValue: number) => {
            return previousValue + currentValue;
          }, 0);
          console.log(this.amountPaid);
          this.registrantFirstName = this.registrations[0].registeredBy.firstName;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.registrationsService.clearState();
  }

}
