import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinal'
})
export class OrdinalPipe implements PipeTransform {

  transform(ordinal: number): string {
      const suffix = ['th', 'st', 'nd', 'rd'];
      const modulus = ordinal % 100;
      return `${ ordinal }${ (suffix[(modulus - 20) % 10] || suffix[modulus] || suffix[0]) }`;
  }

}
