import { Component, ElementRef, Input, OnInit, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { flatten } from 'flat';
import { asyncScheduler } from 'rxjs';

import { checkDuplicates } from '@app/lib/validators/duplicates.validator';
import { IMeeting } from '@app/lib/interfaces';
import { MembershipsService } from '@app/memberships/memberships.service';
import { RegistrationsService } from '@app/registrations/registrations.service';
import { RegistrationValidators } from '@app/registrations/registration.validators';
import { regexPatterns } from '@app/lib/regex-patterns';

@Component({
  selector: 'multi-registration-form',
  templateUrl: './multi-registration.component.html',
})
export class MultiRegistrationComponent implements OnInit {

  @Input()
  meeting: IMeeting = null;

  @ViewChildren('email')
  emails: QueryList<ElementRef>;

  form: UntypedFormGroup;
  registeredByForm: UntypedFormGroup;
  validationMessages = flatten({
    email: {
      duplicate: 'This email already exists',
      duplicateRegistration: 'This person is already registered for this meeting',
      expired: 'This membership has expired. They will need to renew before we can register them as part of a group',
      pattern: 'Please enter a valid email',
      pending: 'This membership is still pending. We will need to receive payment before we can register them as part of a group',
      required: 'This field is required',
    },
  });

  constructor (
    private formBuilder: UntypedFormBuilder,
    private membershipsService: MembershipsService,
    private registrationsService: RegistrationsService,
    private registrationValidators: RegistrationValidators,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.meeting) {
      this.form = this.formBuilder.group({
        registrants: this.formBuilder.array([this.initRegistrantForm()], checkDuplicates('email'))
      });

      this.registeredByForm = this.formBuilder.group({});

      this.focusEmailField();
    }
  }

  initRegistrantForm(email?: string): UntypedFormGroup {
    const formGroup = this.formBuilder.group({
      'email': new UntypedFormControl(
        email ? email : '', {
          validators: [ Validators.required, Validators.pattern(regexPatterns.email), ],
          asyncValidators: [ this.registrationValidators.checkDuplicateRegistrationAndMembership(), ],
        }
      ),
    });

    if (this.meeting.virtual) {
      formGroup.addControl('virtual', new UntypedFormControl(this.meeting.noCost ? true : null, [
        Validators.required,
      ]));
    }

    return formGroup;
  }

  getEmailsFormControl(): UntypedFormArray {
    return this.form.get('registrants') as UntypedFormArray;
  }

  focusEmailField(): void {
    asyncScheduler.schedule(() => {
      this.emails.last.nativeElement.focus();
    });
  }

  addRegistrant(): void {
    const control = this.getEmailsFormControl();
    control.push(this.initRegistrantForm());

    this.focusEmailField();
  }

  removeRegistrant(index: number): UntypedFormArray {
    const control = this.getEmailsFormControl();
    control.removeAt(index);

    return control;
  }

  next(): void {
    const emails = this.form.value.registrants.map(obj => obj.email).join(',');

    this.registrationsService.setState({
      ...this.registeredByForm.value,
      registrantInfo: this.form.value.registrants,
    });

    this.membershipsService.lookupMembers(emails).then(() => {
      this.router.navigate(['/registration/attendees']);
    });
  }

}
