import { Injectable } from '@angular/core';

import { RegistrationsState } from '@app/registrations/registrations.state';
import { Store } from '@app/lib/state/store';

@Injectable()
export class RegistrationsStore extends Store<RegistrationsState> {

  constructor () {
    super(new RegistrationsState());
  }

  clearState(): void {
    this.setState(new RegistrationsState());
  }
}
