import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { getEnvironmentValues } from '@app/environments/utils';
import {
  IMemberPaymentData,
  IMembershipPayment,
  IRegistrationPayment,
  IRegistrationPaymentData
} from '@app/lib/interfaces';

@Injectable()
export class PaymentsService {

  baseUrl = `${ getEnvironmentValues().api.url }/payments`;

  constructor (
    private httpClient: HttpClient,
  ) {}

  async makeRegistrationPayment(registrationPaymentData: IRegistrationPaymentData): Promise<IRegistrationPayment> {
    return this.httpClient
                .post<IRegistrationPayment>(`${ this.baseUrl }/registration`, registrationPaymentData)
                .toPromise()
                .then(payment => payment);
  }

  async makeMembershipPayment(memberPaymentData: IMemberPaymentData): Promise<IMembershipPayment> {
    return this.httpClient
                .post<IMembershipPayment>(`${ this.baseUrl }/membership`, memberPaymentData)
                .toPromise()
                .then(payment => payment);
  }

}
