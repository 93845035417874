import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IMeeting } from '@app/lib/interfaces';
import { RegistrationsService } from '@app/registrations/registrations.service';

@Component({
  selector: 'meeting-wrapper',
  styleUrls: [
    './meeting-wrapper.component.scss',
  ],
  templateUrl: './meeting-wrapper.component.html',
})
export class MeetingWrapperComponent implements OnInit, OnDestroy {

  meeting: IMeeting = null;
  subscription: Subscription;

  constructor (
    private registrationsService: RegistrationsService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.registrationsService.state$.subscribe(state => {
      this.meeting = state.selectedMeeting;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
