import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { getEnvironmentValues } from '@app/environments/utils';

@Component({
  selector: 'app-recaptcha-form',
  templateUrl: './recpatcha-form.component.html',
})
export class RecaptchaFormComponent implements OnInit {

  @Input()
  parentForm: UntypedFormGroup;

  @Output()
  recpatchaIsValid = new EventEmitter<boolean>();

  platformIsBrowser: boolean;
  recaptchaKey: string;

  constructor (
    @Inject(PLATFORM_ID)
    private platformId: string,
  ) {
    this.platformIsBrowser = isPlatformBrowser(this.platformId);
    this.recaptchaKey = getEnvironmentValues().google.reCaptchaKey;
  }

  ngOnInit(): void {
    this.parentForm.addControl('recaptcha', new UntypedFormControl('', [Validators.required]));
  }

  recaptchaSuccess(): void {
    this.recpatchaIsValid.emit(true);
  }

}
