import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IMeeting } from '@app/lib/interfaces';
import { MeetingsService } from '@app/meetings/meetings.service';
import { RegistrationsService } from '@app/registrations/registrations.service';

@Component({
  selector: 'register-button',
  template: `
    <button type="button" class="btn btn-lg btn-primary" *ngIf="meeting && meeting.isConfirmed && !meeting.hasTranspired" (click)="register()">{{ text }}</button>
  `
})
export class RegisterButtonComponent implements OnInit {

  @Input()
  meeting: IMeeting = null;
  text = 'Register';

  constructor (
    private meetingsService: MeetingsService,
    private registrationsService: RegistrationsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.meeting && this.meeting.noCost) {
      this.text = `${ this.text } Free`;
    }
  }

  navigate(): void {
    this.router.navigate(['/register']);
  }

  async register(): Promise<void> {
    if (!this.meeting.location || !this.meeting.contact) {
      this.meeting = await this.meetingsService.getMeetingDetail(this.meeting.slug);
    }

    this.registrationsService.setState({ selectedMeeting: this.meeting });
    this.navigate();
  }

}
