import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { flatten } from 'flat';
import { IRegistration } from '@app/lib/interfaces';

@Component({
  selector: 'student-info-form',
  template: `
    <ng-container [formGroup]="form">
      <fieldset>
        <legend>Student Information</legend>
        <div class="row">
          <div [attr.class]="columnsCss">
            <label for="institutionName">University / College Name</label>
            <input class="form-control form-control-lg" id="institutionName" formControlName="institutionName" type="text" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.institutionName" [validationMessagePath]="'institutionName'"></form-errors>
          </div>
          <div [attr.class]="columnsCss">
            <label for="studentId">Student ID#</label>
            <input class="form-control form-control-lg" id="studentId" formControlName="studentId" type="text" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.studentId" [validationMessagePath]="'studentId'"></form-errors>
          </div>
        </div>
      </fieldset>
    </ng-container>
  `
})
export class StudentInfoFormComponent implements OnInit {

  @Input()
  columns = 3;

  @Input()
  form: UntypedFormGroup;

  @Input()
  data: IRegistration = null;

  @Input()
  validationMessages: object = {};

  columnsCss: string;

  ngOnInit(): void {
    if (this.form) {
      this.buildForm();

      this.columnsCss = `col-sm-${ this.columns } col-md-${ this.columns } col-lg-${ this.columns } form-group`;
    }
  }

  buildForm(): void {
    this.form.addControl('institutionName', new UntypedFormControl(this.data ? this.data.institutionName : '', [ Validators.required ]));
    this.form.addControl('studentId', new UntypedFormControl(this.data ? this.data.studentId : '', [ Validators.required ]));

    Object.assign(this.validationMessages, flatten({
      institutionName: {
        required: 'This field is required for students',
      },
      studentId: {
        required: 'This field is required for students',
      }
    }));

    this.form.updateValueAndValidity();
  }

  removeControls(): void {
    this.form.removeControl('institutionName');
    this.form.removeControl('studentId');
    this.form.updateValueAndValidity();
  }

}
