<div class="row">
  <div class="col">
    <h1>Your Information</h1>
    <p>We couldn&#8217;t find a membership for you, so we need a little more information to get you registered.</p>
    <form enctype="application/x-www-form-urlencoded" role="form" *ngIf="form">
      <guest-registration-form [form]="form" [validationMessages]="validationMessages" (registrationTypeWasSelected)="onRegistrationTypeSelected($event)"></guest-registration-form>
      <fieldset class="actions" *ngIf="registrationTypeWasSelected">
        <button type="button" class="btn btn-primary btn-lg" [disabled]="!form.valid" (click)="onNext()">Next</button>
      </fieldset>
    </form>
  </div>
</div>
