import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthState } from '@app/auth/auth.state';
import { AuthStore } from '@app/auth/auth.store';

import { getEnvironmentValues } from '@app/environments/utils';
import { IAuth } from '@app/lib/interfaces';

@Injectable()
export class AuthService {

  baseUrl = `${ getEnvironmentValues().api.url }/auth`;
  state$: Observable<AuthState>;

  constructor (
    private httpClient: HttpClient,
    private store: AuthStore,
  ) {
    this.state$ = this.store.state$;
  }

  login(email: string, password: string): Promise<IAuth> {
    return this.httpClient
                .post<IAuth>(`${ this.baseUrl }/login`, { email, password, })
                .toPromise()
                .then(response => {
                  this.store.setState({ token: response.token });
                  return response;
                });
  }

  logout(): void {
    this.store.setState({ token: null });
  }

  getState(): IAuth {
    return this.store.state;
  }

  setState(newState: IAuth): void {
    this.store.setState(newState);
  }

}
