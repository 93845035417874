<div class="row">
  <section class="col col-sm-12 col-md-10 col-lg-10">
    <div *ngIf="meeting; else loading;">
      <h1>{{ meeting.title }}</h1>
      <div class="content">
        <p *ngIf="meeting.description" [innerHTML]="meeting.description | newlineToBreak"></p>
        <unconfirmed *ngIf="!meeting.hasTranspired && !meeting.isConfirmed"></unconfirmed>
      </div>

      <ng-container *ngIf="meeting.hasTranspired && form">
        <div class="content">
          <form enctype="application/x-www-form-urlencoded" role="form" [formGroup]="form"*ngIf="!meetingMedia">
            <fieldset>
              <legend>Need to download your attendance certificate and/or presentation materials?</legend>
              <p>If you attended this meeting, just enter your email address below.</p>
              <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4">
                  <input class="form-control form-control-lg" formControlName="email" type="email" placeholder="Email" />
                  <form-errors [validationMessages]="validationMessages" [control]="form.controls.email" [validationMessagePath]="'email'"></form-errors>
                </div>
              </div>
            </fieldset>
            <fieldset class="actions">
              <div class="row">
                <div class="col">
                  <button type="submit" class="btn btn-primary btn-lg" [disabled]="!form.valid" (click)="onSubmit()">Submit</button>
                </div>
              </div>
            </fieldset>
          </form>

          <div class="alert alert-warning fade show" role="alert" *ngIf="error">
            <strong>Sorry, we couldn&#8217;t find your attendance.</strong>
          </div>
        </div>

        <div class="row" *ngIf="meetingMedia">
          <div class="col">
            <h2 style="font-weight: 300;">Meeting Certificate and Presentation Materials</h2>
            <div class="row">
              <div class="col-sm-4 col-md-4 col-lg-4">
                <ul class="list-unstyled">
                  <li *ngIf="meetingMedia.shrmCode">
                    <strong>SHRM Code:</strong> {{ meetingMedia.shrmCode }}
                  </li>
                  <li *ngIf="meetingMedia.ceuCode">
                    <strong>CEU Code:</strong> {{ meetingMedia.ceuCode }}
                  </li>
                  <li *ngIf="!checkObjectIsEmpty(meetingMedia.certificate)">
                      <strong>Certificate:</strong> <button class="btn btn-link btn-collapse" (click)="onDownload(meetingMedia.slug, 'certificate', meetingMedia.certificate.filename)" style="margin-left: 0.5rem"><i class="material-icons">cloud_download</i> Download</button>
                  </li>
                </ul>
              </div>
              <div class="col-sm-4 col-md-4 col-lg-4">
                <ul class="list-unstyled">
                  <li *ngIf="!checkObjectIsEmpty(meetingMedia.media1)">
                    <button type="button" class="btn btn-link btn-collapse" (click)="onDownload(meetingMedia.slug, 'media1', meetingMedia.media1.filename)"><i class="material-icons">cloud_download</i> {{ meetingMedia.media1.filename }} <small>({{ meetingMedia.media1.size | fileSize }})</small></button>
                  </li>
                  <li *ngIf="!checkObjectIsEmpty(meetingMedia.media2)">
                    <button type="button" class="btn btn-link btn-collapse" (click)="onDownload(meetingMedia.slug, 'media2', meetingMedia.media2.filename)"><i class="material-icons">cloud_download</i> {{ meetingMedia.media2.filename }} <small>({{ meetingMedia.media2.size | fileSize }})</small></button>
                  </li>
                  <li *ngIf="!checkObjectIsEmpty(meetingMedia.media3)">
                    <button type="button" class="btn btn-link btn-collapse" (click)="onDownload(meetingMedia.slug, 'media3', meetingMedia.media3.filename)"><i class="material-icons">cloud_download</i> {{ meetingMedia.media3.filename }} <small>({{ meetingMedia.media3.size | fileSize }})</small></button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 4rem;">
          <div class="col"><a routerLink="/archived-meetings">Back to Archived Meetings</a></div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="meeting && !meeting.hasTranspired">
      <div class="row row-details">
        <div class="col-sm-12 col-lg-12 col-xl-4">
          <registration-fees class="detail-section" [meeting]="meeting"></registration-fees>
          <register-button [meeting]="meeting"></register-button>
        </div>
        <div class="col-sm-12 col-lg-6 col-xl-4">
          <time-place class="detail-section" [meeting]="meeting" *ngIf="meeting"></time-place>
        </div>
        <div class="col-sm-12 col-lg-6 col-xl-4">
          <div class="row" *ngIf="meeting.speaker">
            <div class="col">
              <speaker class="detail-section" [speaker]="meeting.speaker"></speaker>
            </div>
          </div>
          <div [ngClass]="{ 'row': true, 'mt-4': meeting.speaker }">
            <div class="col">
              <questions class="detail-section" [contact]="meeting.contact" *ngIf="meeting && meeting.contact"></questions>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col"><a routerLink="/">Back to Meetings</a></div>
      </div>
    </ng-container>
  </section>
</div>

<ng-template #loading>
  <activity></activity>
</ng-template>
