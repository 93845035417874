import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { getEnvironmentValues } from '@app/environments/utils';
import { IMeeting, IMeetingMedia } from '@app/lib/interfaces';
import { MeetingsState } from '@app/meetings/meetings.state';
import { MeetingsStore } from '@app/meetings/meetings.store';

@Injectable()
export class MeetingsService {

  baseUrl = `${ getEnvironmentValues().api.url }/meetings`;
  state$: Observable<MeetingsState>;

  constructor (
    private httpClient: HttpClient,
    private store: MeetingsStore,
  ) {
    this.state$ = this.store.state$;
  }

  getMeetings(past?: boolean): Promise<IMeeting[]> {
    const url = past ? `${ this.baseUrl }?past` : this.baseUrl;

    return this.httpClient
                .get<IMeeting[]>(url)
                .pipe(
                  map(meetings => {
                    if (past) {
                      this.store.setState({
                        pastMeetings: meetings,
                      });
                    }
                    else {
                      this.store.setState({
                        upcomingMeetings: meetings.filter(meeting => !meeting.pinToStart),
                        pinnedMeetings: meetings.filter(meeting => meeting.pinToStart),
                      });
                    }

                    return meetings;
                  }),
                )
                .toPromise();
  }

  async getMeetingDetail(slug: string): Promise<IMeeting> {
    const url = `${ this.baseUrl }/${ slug }`;

    return this.httpClient
                .get<IMeeting>(url)
                .toPromise();
  }

  async getMeetingMedia(meetingId: string, email: string): Promise<IMeetingMedia> {
    const url = `${ this.baseUrl }/${ meetingId }/media/?email=${ email }`;
    const meetingMedia = this.store.state.meetingMedia || [] as IMeetingMedia[];

    return this.httpClient
                .get<IMeetingMedia>(url)
                .pipe(
                  tap(result => {
                    const index = meetingMedia.findIndex(mM => mM.meetingId === meetingId);
                    if (index === -1) {
                      meetingMedia.push(result);
                    }
                    this.store.setState({ meetingMedia });
                  })
                )
                .toPromise();
  }

  download(slug: string, key: string): Promise<Blob> {
    return this.httpClient
                .get(`${ this.baseUrl }/download/${ slug }/${ key }`, { responseType: 'blob' })
                .toPromise();
  }
}
