import { Component, ChangeDetectorRef, Input, OnInit, AfterContentChecked } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';

import { IMeeting } from '@app/lib/interfaces';
import { scrollTo } from '@app/lib/animate';
import { UserAgentService } from '@app/lib/services/user-agent.service';

@Component({
  selector: 'registration-form',
  templateUrl: './registration-form.component.html',
})
export class RegistrationFormComponent implements OnInit, AfterContentChecked {

  @Input()
  meeting: IMeeting = null;

  form: UntypedFormGroup = null;
  numberOfRegistrants: number = null;
  numberOfRegistrantsSubscription: Subscription;
  validationMessages = {};

  constructor (
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private userAgentService: UserAgentService,
  ) {}

  ngOnInit(): void {
    if (this.meeting) {
      this.form = this.formBuilder.group({});
    }
  }

  ngAfterContentChecked(): void {
    // detect changes caused by the instantiation of components that modify
    // properties of this component. Prevents "ExpressionChangedAfterItHasBeenCheckedError"
    this.changeDetectorRef.detectChanges();
  }

  onNumberOfPeopleSelected(numberOfPeople: number): void {
    this.numberOfRegistrants = numberOfPeople;

    if (this.userAgentService.isMobile) {
      const top = $('#formContainer').offset().top - 100;
      scrollTo(top);
    }
  }

}
