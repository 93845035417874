import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { IRegistration } from '@app/lib/interfaces';
import { PersonFormComponent } from '@app/lib/components/forms/person/person-form.component';
import { StudentInfoFormComponent } from '@app/lib/components/forms/student-info-form/student-info-form.component';

@Component({
  selector: 'guest-registration-form',
  template: `
    <registration-type-form [form]="form" [registrationTypeId]="(registrationData && registrationData.registrationType) || ''" (registrationTypeSelected)="onRegistrationTypeSelected($event)"></registration-type-form>

    <ng-container *ngIf="registrationTypeSelected">
      <person-form #personForm [emailEnabled]="emailEnabled" [form]="form" [validationMessages]="validationMessages" [jobTitleCols]="jobTitleCols" [data]="registrationData" [personInfoColumns]="personInfoCols"></person-form>
      <student-info-form #studentInfoForm *ngIf="showStudentForm" [form]="form" [validationMessages]="validationMessages" [data]="registrationData" [columns]="6"></student-info-form>
    </ng-container>
  `
})
export class GuestRegistrationFormComponent {

  @Input()
  emailEnabled = false;

  @Input()
  form: UntypedFormGroup;

  @Input()
  jobTitleCols = 3;

  @Input()
  personInfoCols = 3;

  @Input()
  registrationData: IRegistration = null;

  @Output()
  registrationTypeWasSelected = new EventEmitter<boolean>();

  @Input()
  validationMessages: object;

  @ViewChild('personForm')
  personFormComponent: PersonFormComponent;

  @ViewChild('studentInfoForm')
  studentInfoFormComponent: StudentInfoFormComponent;

  registrationTypeSelected: string = null;
  showStudentForm = false;

  onRegistrationTypeSelected(value: string): void {
    // re-focus the first name field if the value changes
    const focusFirstName = this.registrationTypeSelected && value;

    this.registrationTypeSelected = value;
    this.registrationTypeWasSelected.emit(value ? true : false);
    this.showStudentForm = value.toLocaleLowerCase() === 'student';

    if (focusFirstName) {
      this.personFormComponent.focusPersonFirstName();
    }

    if (this.studentInfoFormComponent) {
      this.showStudentForm ? this.studentInfoFormComponent.buildForm() : this.studentInfoFormComponent.removeControls();
    }
  }

}
