import { asyncScheduler } from 'rxjs';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { flatten } from 'flat';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { areaCodeValidator } from '@app/lib/validators/area-code.validator';

import { IMember, IMemberPost, IRegistration } from '@app/lib/interfaces';
import { regexPatterns } from '@app/lib/regex-patterns';

@Component({
  selector: 'person-form',
  template: `
    <ng-container [formGroup]="form">
      <fieldset>
        <legend>{{ legend }}</legend>
        <div class="row">
          <div [attr.class]="personInfoColsCss">
            <label for="personFirstName">First Name</label>
            <input class="form-control form-control-lg" id="personFirstName" formControlName="firstName" type="text" #personFirstName />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.firstName" [validationMessagePath]="'firstName'"></form-errors>
          </div>
          <div [attr.class]="personInfoColsCss">
            <label for="personLastName">Last Name</label>
            <input class="form-control form-control-lg" id="personLastName" formControlName="lastName" type="text" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.lastName" [validationMessagePath]="'lastName'"></form-errors>
          </div>
          <div *ngIf="emailEnabled" [attr.class]="personInfoColsCss">
            <label for="personEmail">Email</label>
            <input class="form-control form-control-lg" id="personEmail" formControlName="email" type="email" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.email" [validationMessagePath]="'email'"></form-errors>
          </div>
          <div [attr.class]="personInfoColsCss">
            <label for="personPhone">Phone</label>
            <input mask="000-000-0000" [showMaskTyped]="true" class="form-control form-control-lg" id="personPhone" formControlName="phone" type="tel" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.phone" [validationMessagePath]="'phone'"></form-errors>
          </div>
        </div>
      </fieldset>
      <fieldset *ngIf="enableCompanyInfo">
        <div class="row">
          <div class="col form-group">
            <legend>Company Information <small class="help">(optional)</small></legend>
            <div class="row">
              <div [attr.class]="companyNameColsCss">
                <label for="memberCompanyName">Company Name</label>
                <input class="form-control form-control-lg" id="memberCompanyName" formControlName="companyName" type="text" />
              </div>
              <div [attr.class]="jobTitleColsCss">
                <label for="memberJobTitle">Job Title</label>
                <input class="form-control form-control-lg" id="memberJobTitle" formControlName="jobTitle" type="text" />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </ng-container>
  `,
})
export class PersonFormComponent implements OnInit {

  @ViewChild('personFirstName')
  personFirstName: ElementRef;

  @Input()
  companyNameCols = 6;

  @Input()
  disableFocus = false;

  @Input()
  emailEnabled = true;

  @Input()
  enableCompanyInfo = true;

  @Input()
  data: IMember | IMemberPost | IRegistration = null;

  @Input()
  form: UntypedFormGroup = null;

  @Input()
  jobTitleCols = 6;

  @Input()
  legend = 'About You';

  @Input()
  personInfoColumns = 3;

  @Input()
  validationMessages: object = {};

  companyNameColsCss: string;
  jobTitleColsCss: string;
  personInfoColsCss: string;

  ngOnInit(): void {
    this.companyNameColsCss = `col-sm-${ this.companyNameCols } col-md-${ this.companyNameCols } col-lg-${ this.companyNameCols } form-group`;
    this.jobTitleColsCss = `col-sm-${ this.jobTitleCols } col-md-${ this.jobTitleCols } col-lg-${ this.jobTitleCols } form-group`;

    if (this.form) {
      this.form.addControl('firstName', new UntypedFormControl(this.data ? this.data.firstName : '', [
        Validators.required, Validators.pattern(regexPatterns.name),
      ]));
      this.form.addControl('lastName', new UntypedFormControl(this.data ? this.data.lastName : '', [
        Validators.required, Validators.pattern(regexPatterns.name),
      ]));
      this.form.addControl('phone', new UntypedFormControl(this.data ? this.data.phone : '', [
        Validators.required, areaCodeValidator(),
      ]));

      if (this.enableCompanyInfo) {
        this.form.addControl('companyName', new UntypedFormControl(this.data ? this.data.companyName : ''));
        this.form.addControl('jobTitle', new UntypedFormControl(this.data ? this.data.jobTitle : ''));
      }

      Object.assign(this.validationMessages, flatten({
        firstName: {
          required: 'First Name is required',
          pattern: 'This value is not accepted',
        },
        lastName: {
          required: 'Last Name is required',
          pattern: 'This value is not accepted',
        },
        phone: {
          required: 'Phone is required',
          invalidAreaCode: 'Please enter a valid phone number',
        },
      }));

      if (this.emailEnabled) {
        this.form.addControl('email', new UntypedFormControl(this.data ? this.data.email : '', [Validators.required, Validators.pattern(regexPatterns.email)]));

        Object.assign(this.validationMessages, flatten({
          email: {
            pattern: 'Please enter a valid email',
            required: 'Email is required',
          },
        }));
      }

      if (!this.disableFocus) {
        this.focusPersonFirstName();
      }

      this.personInfoColsCss = `col-sm-${ this.personInfoColumns } col-md-${ this.personInfoColumns } col-lg-${ this.personInfoColumns } form-group`;
    }
  }

  focusPersonFirstName(): void {
    asyncScheduler.schedule(() => {
      this.personFirstName.nativeElement.focus();
    });
  }

}
