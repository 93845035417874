import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { UserAgentService } from '@app/lib/services/user-agent.service';

@Pipe({
  name: 'mobileDateTime'
})
export class MobileDateTimePipe implements PipeTransform {

  platformIsBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: string,

    private sanitizer: DomSanitizer,
    private userAgentService: UserAgentService
  ) {
    this.platformIsBrowser = isPlatformBrowser(this.platformId);
  }

  transform(value: string): string | SafeHtml {
    if (this.platformIsBrowser && this.userAgentService.isPhone) {
      const [date, startTime, endTime] = value.split(' - ');

      return this.sanitizer.bypassSecurityTrustHtml(`${ date }<br />${ startTime } - ${ endTime }`);
    }

    return value;
  }
}
