import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { getEnvironmentValues } from '@app/environments/utils';
import { IMetaData, IMembership, IRegistrationType } from '@app/lib/interfaces';
import { MetaDataState } from '@app/lib/metadata/metadata.state';
import { MetaDataStore } from '@app/lib/metadata/metadata.store';

@Injectable()
export class MetaDataService {

  baseUrl = `${ getEnvironmentValues().api.url }/metadata`;
  state$: Observable<MetaDataState>;

  constructor (
    private httpClient: HttpClient,
    private store: MetaDataStore,
  ) {
    this.state$ = this.store.state$;
  }

  getMetaData(): void {
    this.httpClient
          .get<IMetaData>(this.baseUrl)
          .pipe(
            map(metadata => metadata),
            distinctUntilChanged(),
          )
          .subscribe(metadata => {
            this.store.setState(metadata);
          });
  }

  getMembership(id: string): IMembership {
    return this.store.state.memberships.find(membership => membership.id === id);
  }

  getRegistrationType(_id: string): IRegistrationType {
    return this.store.state.registrationTypes.find(registrationType => registrationType._id === _id);
  }

}
