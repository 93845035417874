import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { StripeService } from 'ngx-stripe';
import { take } from 'rxjs/operators';

import { IMeeting, IMemberLookup, IRegistration, IRegistrationType, ICreateRegistrationsPostData } from '@app/lib/interfaces';
import { MetaDataService } from '@app/lib/metadata/metadata.service';
import { ONLINE, INVOICE } from '@app/lib/data/payment-methods';
import { PaymentBase } from '@app/lib/components/forms/PaymentBase';
import { RegistrationsService } from '@app/registrations/registrations.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './registration-payment-form.component.scss',
  ],
  templateUrl: './registration-payment-form.component.html',
})
export class RegistrationPaymentFormComponent extends PaymentBase implements OnInit, OnDestroy {

  amountDue: number = null;
  amounts: number[] = [];
  excludeInvoice = false;
  meeting: IMeeting = null;
  memberLookups: IMemberLookup[] = null;
  registrations: IRegistration[] = [];
  registrationTypes: IRegistrationType[] = null;

  constructor (
    protected changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private metaDataService: MetaDataService,
    private registrationsService: RegistrationsService,
    private router: Router,
    protected stripeService: StripeService,
  ) {
    super(changeDetectorRef, stripeService);
  }

  ngOnInit(): void {
    // only take the latest state once
    this.subscription = this.registrationsService.state$.pipe(take(1)).subscribe(state => {
      this.form = this.formBuilder.group({});
      this.meeting = state.selectedMeeting;
      this.memberLookups = state.memberLookups;

      if (this.memberLookups) {
        this.memberLookups.forEach(memberLookup => {

          const amountDue = (memberLookup.virtual || this.meeting.noCost) ? 0 : memberLookup.member.membership.costPerMeeting;
          this.amounts.push(amountDue);

          // Shape registrations data from member lookups
          this.registrations.push({
            firstName: memberLookup.member.firstName,
            lastName: memberLookup.member.lastName,
            email: memberLookup.member.email,
            phone: memberLookup.member.phone,
            meeting: this.meeting.id,
            member: memberLookup.member._id,
            membership: memberLookup.member.membership,
            companyName: memberLookup.member.companyName,
            jobTitle: memberLookup.member.jobTitle,
            amountDue,
            paymentOwed: amountDue > 0,
            virtual: memberLookup.virtual,
          });
        });
      }
      else {
        // Get registration types from state
        this.metaDataService.state$.pipe(take(1)).subscribe(metaDataState => this.registrationTypes = metaDataState.registrationTypes);

        state.registrations.forEach(registration => {
          let costPerMeeting: number;

          if (registration.registrationType) {
            const registrationType = this.registrationTypes.find(rT => rT._id === registration.registrationType);
            costPerMeeting = (registration.virtual || this.meeting.noCost) ? 0 : registrationType.costPerMeeting;

            Object.assign(registration, {
              registrationType,
              amountDue: costPerMeeting,
            });

            if (registrationType.title.toLocaleLowerCase() !== 'guest') {
              this.excludeInvoice = true;
            }

            this.amounts.push(costPerMeeting);
          }
          else {
            // registration is for a member. This condition should only occur for a group registration
            costPerMeeting = (registration.virtual || this.meeting.noCost) ? 0 : registration.member['membership'].costPerMeeting;

            Object.assign(registration, {
              amountDue: costPerMeeting,
              paymentOwed: costPerMeeting > 0,
            });

            this.amounts.push(costPerMeeting);
          }

          this.registrations.push(registration);
        });
      }

      this.amountDue = this.amounts.reduce((accumulator, value) => accumulator + value) + (this.meeting.additionalCost * this.amounts.length);
    });
  }

  async processRegistrations(): Promise<void> {
    this.processing = true;

    const createRegistrationsPostData: ICreateRegistrationsPostData = {
      meeting: this.meeting.id,
      registrationsData: this.registrations,
    };

    let emailMethod = 'sendConfirmationEmail';

    try {
      if (this.meeting.noCost || this.amountDue === 0) {
        // assign amountPaid on each registration to 0
        this.registrations.forEach(registration => Object.assign(registration, { amountPaid: 0, paymentOwed: false }));
      }
      else {
        if (this.paymentMethod === ONLINE) {

          this.registrations.forEach(registration => {
            let amountPaid;

            if (registration['member'] && registration['member']['membership']) {
              amountPaid = registration['member']['membership']['costPerMeeting'];
            }
            else if (registration['membership'] && registration['membership']['costPerMeeting']) {
              amountPaid = registration['membership']['costPerMeeting'];
            }
            else {
              amountPaid = registration.registrationType['costPerMeeting'];
            }

            Object.assign(registration, {
              amountPaid,
              paymentOwed: false,
            });
          });

          const stripeToken = await this.getStripeToken(this.card);
          const registrants = this.registrations.map(r => `${ r.firstName } ${ r.lastName } (${ r.email })`);
          const description = `Meeting Registration(s) for ${ registrants.join(', ') } for ${ this.meeting.title }`;
          const stripeChargeData = this.createStripeChargeData(this.amountDue, stripeToken, description);

          Object.assign(createRegistrationsPostData, { stripeChargeData, });
        }
        else {
          emailMethod = 'sendPendingEmail';

          if (this.paymentMethod === INVOICE) {
            const invoiceData = this.createInvoiceData(this.amountDue);

            Object.assign(createRegistrationsPostData, { invoiceData, });
          }
        }
      }

      const registrations = await this.registrationsService.createRegistrations(createRegistrationsPostData);

      // send pending receipt to `registeredBy` if multiple attendees exist and a payment has not been made
      if (this.amountDue > 0 && registrations.length > 1 && !createRegistrationsPostData.stripeChargeData) {
        const registrationIds = registrations.map(r => r._id);
        await this.registrationsService.sendReceipt(registrationIds, this.meeting.id, this.amountDue);
      }

      // send confirmed/pending emails to attendees
      this.registrationsService[emailMethod](registrations).then(() => {
        this.router.navigate(['/registration/complete']);
      });
    }
    catch (error) {
      this.error = true;
      this.processing = false;
    }
  }

  cancel(): void {
    this.registrationsService.clearState();
    this.router.navigate(['/']);
  }

}
