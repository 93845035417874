<meeting-wrapper>
  <ng-container >
    <h1>{{ meeting.noCost ? 'Complete Registration' : 'Registration Payment' }}</h1>
    <table class="table table-cart">
      <thead>
        <tr>
          <th>Attendee</th>
          <th class="text-right">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let registration of registrations; let i = index;">
          <td>
            <h3 class="item">{{ registration.firstName }} {{ registration.lastName }}</h3>
            <div class="item-description">
              <ul class="list-unstyled">
                <li *ngIf="registration.member">{{ (registration.membership ? registration.membership.title : registration.member.membership.title) }} Membership</li>
                <li *ngIf="registration.registrationType">{{ registration.registrationType.name }}</li>
                <li>{{ registration.email }}</li>
                <li>{{ registration.phone }}</li>
                <li>Attending {{ registration.virtual ? 'virtually' : 'in person' }}</li>
              </ul>
            </div>
          </td>
          <td class="item-cost text-right">
            <ng-container *ngIf="meeting.additionalCost">
              <div>
                <small class="d-block text-muted">Registration Fee</small>
                <sup>$</sup>{{ amounts[i].toFixed(2) }}
              </div>
              <div class="mt-3">
                <small class="d-block text-muted">Special Event Fee</small>
                <sup>$</sup>{{ meeting.additionalCost.toFixed(2) }}
              </div>
            </ng-container>
            <ng-container *ngIf="!meeting.additionalCost">
              <sup>$</sup>{{ amounts[i].toFixed(2) }}
            </ng-container>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>Total Due</td>
          <td class="text-right"><ng-container *ngIf="amountDue > 0"><sup>$</sup>{{ amountDue.toFixed(2) }}</ng-container><ng-container *ngIf="amountDue === 0">FREE</ng-container></td>
        </tr>
      </tfoot>
    </table>

    <form *ngIf="form && !meeting.noCost && amountDue > 0" [formGroup]="form" role="form" enctype="application/x-www-form-urlencoded" novalidate>
      <payment-method-form [form]="form" (paymentMethodSelected)="onPaymentMethodSelected($event)" [excludeInvoice]="excludeInvoice"></payment-method-form>

      <fieldset *ngIf="paymentMethod === online">
        <div class="row">
          <div [ngClass]="{'col-sm-12 col-md-6 col-lg-8 form-group': true, 'has-error': cardErrorMessage}">
            <h5>Card Information</h5>
            <ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
            <ul class="error-list" *ngIf="cardErrorMessage">
              <li>{{ cardErrorMessage }}</li>
            </ul>
          </div>
        </div>
      </fieldset>

      <fieldset *ngIf="paymentMethod === checkCash">
        <div class="row">
          <div class="col">
            <payable-to [meeting]="meeting"></payable-to>
          </div>
        </div>
      </fieldset>

      <invoice-form *ngIf="paymentMethod === invoice" [form]="form" [validationMessages]="validationMessages" [amountDue]="amountDue"></invoice-form>

      <fieldset>
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-primary btn-lg" (click)="processRegistrations()" [disabled]="!form.valid || processing">
              <ng-container *ngIf="!processing">Submit</ng-container>
              <ng-container *ngIf="processing"><i class="fa fa-spinner fa-pulse fa-fw"></i> Processing</ng-container>
            </button>
            <button type="button" class="btn btn-link btn-lg" data-toggle="modal" data-target="#cancelRegistrationModal" [disabled]="processing">Cancel</button>
          </div>
        </div>
        <div class="row" id="transactionNote" *ngIf="processing && paymentMethod === online">
          <div class="col">
            <div class="alert alert-warning fade show" role="alert" style="margin-top: 1.5rem;">
              <strong>Attention: </strong>Please do not close your browser window or navigate while your transaction is processing.
            </div>
          </div>
        </div>
      </fieldset>

    </form>

    <div *ngIf="meeting.noCost || amountDue === 0" class="row">
      <div class="col">
        <button type="button" class="btn btn-primary btn-lg" (click)="processRegistrations()" [disabled]="processing">
          <ng-container *ngIf="!processing">Submit</ng-container>
          <ng-container *ngIf="processing"><i class="fa fa-spinner fa-pulse fa-fw"></i> Processing</ng-container>
        </button>
      </div>
    </div>

    <div class="modal fade modal-danger" id="cancelRegistrationModal" tabindex="-1" role="dialog" aria-labelledby="cancelRegistrationsLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="icon-wrapper">
          <i class="fa fa-exclamation-triangle"></i>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <h2 id="cancelRegistrationsLabel">Are you sure you want to cancel your registration?</h2>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="cancel()" data-dismiss="modal" aria-label="Cancel Registration">Yes</button>
            <button type="button" class="btn" data-dismiss="modal" aria-label="Close">No</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</meeting-wrapper>
