import { Injectable } from '@angular/core';

import { NewsState } from './news.state';
import { Store } from '@app/lib/state/store';

@Injectable()
export class NewsStore extends Store<NewsState> {

  constructor () {
    super(new NewsState());
  }

  clearState(): void {
    this.setState(new NewsState());
  }
}
