import { Component } from '@angular/core';

@Component({
  template: `
    <div class="row">
      <div class="col">
        <h1>We&#8217;re sorry, an unexpected error has occured.</h1>
      </div>
    </div>
  `
})
export class OopsComponent {}
