import { Component } from '@angular/core';

@Component({
  selector: 'unconfirmed',
  styleUrls: [
    './unconfirmed.component.scss',
  ],
  template: `<strong class="unconfirmed">NOTE: This meeting is not yet confirmed</strong>`
})
export class UnconfirmedComponent {}
