<div class="row">
  <div class="col">
    <h1>Administrator Login</h1>
    <form *ngIf="form" enctype="application/x-www-form-urlencoded" [formGroup]="form" role="form">
      <fieldset>
        <div class="row">
          <div class="col-sm-12 col-md-3 form-group">
            <label for="email">Email</label>
            <input class="form-control form-control-lg" id="email" formControlName="email" type="email" #emailControl />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.email" [validationMessagePath]="'email'"></form-errors>
          </div>
          <div class="col-sm-12 col-md-3 form-group">
            <label for="password">Password</label>
            <input class="form-control form-control-lg" id="password" formControlName="password" type="password" />
            <form-errors [validationMessages]="validationMessages" [control]="form.controls.password" [validationMessagePath]="'password'"></form-errors>
          </div>
        </div>
        <div class="row actions">
          <div class="col">
            <button type="submit" class="btn btn-primary btn-lg" [disabled]="!form.valid || processing" (click)="onSubmit()">Submit</button>
          </div>
        </div>
        <div *ngIf="showError" class="row">
          <div class="col-sm-6">
            <div class="alert alert-warning" role="alert">
              Sorry, please check your username and password and try again.
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
