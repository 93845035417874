import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { IMeeting } from '@app/lib/interfaces';
import { RegistrationsService } from '@app/registrations/registrations.service';

@Component({
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit, OnDestroy {

  meeting: IMeeting = null;
  subscription: Subscription;

  constructor (
    private registrationsService: RegistrationsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscription = this.registrationsService.state$.subscribe(state => {
      this.meeting = state.selectedMeeting;

      if (!this.meeting) {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
