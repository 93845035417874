import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { getEnvironmentValues } from '@app/environments/utils';
import { IContent } from '../interfaces';

@Injectable()
export class ContentService {

  baseUrl = `${ getEnvironmentValues().api.url }/content`;

  constructor (
    private httpClient: HttpClient,
  ) {}

  async getContent(path: string): Promise<IContent> {
    return this.httpClient
                .get<IContent>(`${ this.baseUrl }/${ path }`)
                .toPromise()
                .then(content => content);
  }

}
