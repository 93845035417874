import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { Subscription } from 'rxjs';

import { getEnvironmentValues } from '@app/environments/utils';
import { IMeeting } from '@app/lib/interfaces';
import { MeetingsService } from '@app/meetings/meetings.service';

@Component({
  templateUrl: './archived-meetings.component.html',
})
export class ArchivedMeetingsComponent implements OnInit, OnDestroy {

  loading = false;
  loadMeetingsError = false;
  meetings: IMeeting[] = null;
  paginationConfig: PaginationInstance = {
    itemsPerPage: getEnvironmentValues().pagination.defaultItemsPerPage,
    currentPage: 1
  };
  subscription: Subscription;

  constructor (
    private meetingsService: MeetingsService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.meetingsService.state$.subscribe(state => {
      this.meetings = state.pastMeetings;
    });

    if (!this.meetings) {
      this.meetingsService
          .getMeetings(true)
          .catch(() => this.loadMeetingsError = true)
          .finally(() => this.loading = false);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
