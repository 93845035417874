import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listToSentence'
})
export class ListToSentencePipe implements PipeTransform {

  transform(items: string[]): string {
    if (items.length && items.length > 1) {
      if (items.length === 2) {
        return items.join(' and ');
      }

      const values = [];
      items.forEach((item, index) => {
        const ordinalIndex = index + 1;

        if (ordinalIndex === items.length - 1) {
          values.push(`${ item } and `);
        }
        else if (ordinalIndex === items.length) {
          values.push(item);
        }
        else {
          values.push(`${ item }, `);
        }
      });

      return values.join('');
    }

    return items[0] || '';
  }

}
