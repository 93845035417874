import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {

  transform(items: any[], suffix?: string): string {
    return items ? items.length > 1 ? `${ suffix || 's' }` : '' : '';
  }

}
