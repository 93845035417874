import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { flatten } from 'flat';
import { Router } from '@angular/router';
import { asyncScheduler } from 'rxjs';

import { ICreateRegistrationsPostData, IMeeting, IMemberLookup } from '@app/lib/interfaces';
import { MembersService } from '@app/members/members.service';
import { RegistrationsService } from '@app/registrations/registrations.service';
import { RegistrationValidators } from '@app/registrations/registration.validators';
import { regexPatterns } from '@app/lib/regex-patterns';

@Component({
  selector: 'single-registration-form',
  templateUrl: './single-registration-form.component.html',
})
export class SingleRegistrationFormComponent implements OnInit {

  @Input()
  meeting: IMeeting;

  @ViewChild('registrantEmail')
  registrantEmail: ElementRef;

  emailResent = false;
  form: UntypedFormGroup;
  processing = false;
  validationMessages = flatten({
    registrantEmail: {
      pattern: 'Please enter a valid email address',
      required: 'Please enter your email address',
      duplicate: '', // Handled as alert in template
      expired: '',
      pending: '',
    },
    contactMethod: {
      pattern: 'Automated form submissions are not allowed',
    }
  });
  year = new Date().getFullYear();

  constructor (
    private formBuilder: UntypedFormBuilder,
    private membersService: MembersService,
    private registrationsService: RegistrationsService,
    private registrationValidators: RegistrationValidators,
    private router: Router,
  ) {}

  get error(): boolean {
    if (this.form) {
      const control = <UntypedFormControl>this.form.get('registrantEmail');

      return [
        control.hasError('duplicateRegistration'),
        control.hasError('expired'),
        control.hasError('pending'),
      ].some(val => val);
    }

    return false;
  }

  get virtualAttendanceValue(): boolean {
    if (this.form.value.virtual !== undefined) {
      return this.form.value.virtual;
    }

    return this.meeting.virtual;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      registrantEmail: new UntypedFormControl(
        '', {
          validators: [ Validators.required, Validators.pattern(regexPatterns.email), ],
          asyncValidators: [ this.registrationValidators.checkDuplicateRegistrationAndMembership(), ],
        }
      ),
      contactMethod: [
        0, [
          Validators.required, Validators.pattern(new RegExp(/^[0]{1}$/)),
        ]
      ],
    });

    if (this.meeting.location && this.meeting.virtual) {
      this.form.addControl('virtual', new UntypedFormControl(null, [
        Validators.required,
      ]));
    }

    this.registrationsService.setState({ memberLookups: null, registrations: null, });

    asyncScheduler.schedule(() => {
      this.registrantEmail.nativeElement.focus();
    });
  }

  getRegistrantEmailControl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('registrantEmail');
  }

  async processRegistrations(): Promise<void> {
    this.processing = true;

    const control = this.getRegistrantEmailControl();
    const memberLookups: IMemberLookup[] = await this.membersService.lookupMembers([control.value]);
    memberLookups[0].virtual = this.virtualAttendanceValue;

    this.registrationsService.setState({ memberLookups, });

    if (memberLookups && memberLookups[0].member) {
      const member = memberLookups[0].member;

      // if no amount owed, register member
      if (memberLookups[0].virtual || (member.membership.costPerMeeting === 0 && this.meeting.additionalCost === 0) || this.meeting.noCost) {
        const createRegistrationsPostData: ICreateRegistrationsPostData = {
          meeting: this.meeting.id,
          registrationsData: [
            {
              firstName: member.firstName,
              lastName: member.lastName,
              email: member.email,
              phone: member.phone,
              member: member._id,
              membershipType: member.membership.title,
              companyName: member.companyName,
              jobTitle: member.jobTitle,
              meeting: this.meeting.id,
              paymentOwed: false,
              amountPaid: 0,
              amountDue: 0,
              virtual: this.virtualAttendanceValue,
            },
          ]
        };

        const registration = await this.registrationsService.createRegistrations(createRegistrationsPostData);
        this.registrationsService.sendConfirmationEmail(registration);

        this.router.navigate(['/registration/complete']);
      }
      else {
        this.router.navigate(['registration/payment']);
      }
    }
    else {
      this.router.navigate(['/registration/guest-other']);
    }
  }

  cancel(): void {
    this.registrationsService.clearState();
    this.form.reset();
    this.emailResent = false;
  }

  resendEmail(event: Event): void {
    event.preventDefault();

    const control = this.getRegistrantEmailControl();
    this.registrationsService.resendConfirmationEmail(control.value, this.meeting.id).then(() => {
      this.form.reset({ registrationEmail: '', contactMethod: 0 });
      this.emailResent = true;
    });
  }

  goToGuestRegistration(event: Event): void {
    event.preventDefault();

    // manually create memberLookup from email
    const memberLookup: IMemberLookup = {
      order: 0,
      email: this.getRegistrantEmailControl().value,
      member: null,
      virtual: this.virtualAttendanceValue,
    };

    this.registrationsService.setState({ memberLookups: [memberLookup] });

    this.router.navigate(['/registration/guest-other']);
  }

}
