import { Component, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { IContact } from '@app/lib/interfaces';
import { MetaDataService } from '@app/lib/metadata/metadata.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'questions',
  templateUrl: './questions.component.html',
})
export class QuestionsComponent implements OnInit, OnDestroy {

  @Input()
  contact: IContact = null;

  subscription: Subscription;

  constructor (
    private metaDataService: MetaDataService,
  ) {}

  ngOnInit(): void {
    if (!this.contact) {
      this.subscription = this.metaDataService.state$.subscribe(state => {
        this.contact = state.primaryContact;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
