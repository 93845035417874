import { Component, Input, ViewEncapsulation } from '@angular/core';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'pagination-links',
  templateUrl: './pagination-links.component.html',
})
export class PaginationLinksComponent {

  @Input()
  paginationApi: any;

}
