<fieldset [formGroup]="form" *ngIf="form && form.controls.invoice">
  <legend>Whom should we invoice?</legend>
  <ng-container formGroupName="invoice">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="companyName">Company Name</label>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <input #companyName class="form-control form-control-lg" id="companyName" formControlName="companyName" type="text" />
              <form-errors [validationMessages]="validationMessages" [control]="form.get('invoice.companyName')" [validationMessagePath]="'invoice.companyName'"></form-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="companyName">Attention</label>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <input class="form-control form-control-lg" id="attentionTo" formControlName="attentionTo" type="text" />
              <form-errors [validationMessages]="validationMessages" [control]="form.get('invoice.attentionTo')" [validationMessagePath]="'invoice.attentionTo'"></form-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="email">Email Invoice To</label>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <input class="form-control form-control-lg" id="email" formControlName="email" type="text" />
              <form-errors [validationMessages]="validationMessages" [control]="form.get('invoice.email')" [validationMessagePath]="'invoice.email'"></form-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="address1">Address</label>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <input class="form-control form-control-lg" id="address1" formControlName="address1" type="text" />
              <form-errors [validationMessages]="validationMessages" [control]="form.get('invoice.address1')" [validationMessagePath]="'invoice.address1'"></form-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="address2">P.O. Box, Suite <small>*optional</small></label>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <input class="form-control form-control-lg" id="address2" formControlName="address2" type="text" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="city">City</label>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <input class="form-control form-control-lg" id="city" formControlName="city" type="text" />
              <form-errors [validationMessages]="validationMessages" [control]="form.get('invoice.city')" [validationMessagePath]="'invoice.city'"></form-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="state">State</label>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <select formControlName="state" id="state" class="form-control form-control-lg">
                <option [value]="state" *ngFor="let state of states">{{ state }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="zip">Zip</label>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <input class="form-control form-control-lg" id="zip" formControlName="zip" type="text" maxlength="5" />
              <form-errors [validationMessages]="validationMessages" [control]="form.get('invoice.zip')" [validationMessagePath]="'invoice.zip'"></form-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="phone">Phone</label>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <input mask="000-000-0000" [showMaskTyped]="true" class="form-control form-control-lg" id="phone" formControlName="phone" type="tel" />
              <form-errors [validationMessages]="validationMessages" [control]="form.get('invoice.phone')" [validationMessagePath]="'invoice.phone'"></form-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</fieldset>
