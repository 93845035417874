import { IInvoice, IMeeting, IMemberLookup, IPerson, IRegistration, IRegistrantInfo } from '@app/lib/interfaces';

export class RegistrationsState {
  amountPaid?: number = null;
  invoice?: IInvoice = null;
  invoiced?: boolean;
  memberLookups?: IMemberLookup[] = null;
  paid?: boolean;
  registeredBy?: IPerson = null;
  registrantInfo?: IRegistrantInfo[] = null;
  registrations?: IRegistration[] = null;
  selectedMeeting?: IMeeting = null;
}
