import { IPaymentMethod } from '@app/lib/interfaces';

export const ONLINE = 'Online';
export const CHECK_CASH = 'Check or Cash';
export const INVOICE = 'Invoice';

export const PAYMENT_METHODS: IPaymentMethod[] = [
  {
    description: 'Online using a credit card',
    value: ONLINE,
  },
  {
    description: 'Check or cash at the door',
    value: CHECK_CASH,
  },
  {
    description: 'Invoice my company',
    value: INVOICE,
  }
];
