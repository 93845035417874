<h3>Questions?</h3>
<ng-container *ngIf="contact">
  <ul class="list-unstyled">
    <li>
      <strong>{{ contact.name.first }} {{ contact.name.last }}</strong>
    </li>
    <li>
      <a [attr.href]="'tel:' + contact.phone">{{ contact.phone }}</a>
    </li>
    <li>
      <a [attr.href]="'mailto:' + contact.email">{{ contact.email }}</a>
    </li>
  </ul>
</ng-container>
