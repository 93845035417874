import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { flatten } from 'flat';
import * as FileSaver from 'file-saver';

import { IMeeting, IMeetingMedia } from '@app/lib/interfaces';
import { isEmptyObject } from '@app/lib/utils';
import { MeetingsService } from '@app/meetings/meetings.service';
import { regexPatterns } from '@app/lib/regex-patterns';

@Component({
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './meeting-detail.component.scss'
  ],
  templateUrl: './meeting-detail.component.html',
})
export class MeetingDetailComponent implements OnInit, OnDestroy {

  error = false;
  form: UntypedFormGroup;
  formValueSubscription: Subscription;
  meeting: IMeeting = null;
  meetingMedia: IMeetingMedia = null;
  meetingMediaSubscription: Subscription;
  routerSubscription: Subscription;
  validationMessages = flatten({
    email: {
      required: 'This field is required',
      pattern: 'Please enter a valid email address',
    }
  });

  constructor (
    private formBuilder: UntypedFormBuilder,
    private meetingsService: MeetingsService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.activatedRoute.params.subscribe(params => {
      this.meetingsService.getMeetingDetail(params['slug']).then(meeting => {
        this.meeting = meeting;

        if (this.meeting) {
          this.form = this.formBuilder.group({
            email: [
              '', [ Validators.required, Validators.pattern(regexPatterns.email), ]
            ]
          });

          this.formValueChangeHandler();

          this.meetingMediaSubscription = this.meetingsService.state$.subscribe(state => {
            this.meetingMedia = this.meeting && state.meetingMedia && state.meetingMedia.find(m => m.meetingId === this.meeting.id);
          });
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.meetingMediaSubscription) {
      this.meetingMediaSubscription.unsubscribe();
    }
    this.routerSubscription.unsubscribe();
  }

  formValueChangeHandler(): void {
    const control = this.form.get('email');

    this.formValueSubscription = control.valueChanges.subscribe(value => {
      this.error = false;
    });
  }

  onSubmit(): void {
    this.meetingsService
        .getMeetingMedia(this.meeting.id, this.form.value.email)
        .then(() => {
          this.error = false;
        })
        .catch(() => {
          this.error = true;
        });
  }

  checkObjectIsEmpty(obj: Object): boolean {
    return isEmptyObject(obj);
  }

  onDownload(meetingId: string, key: string, fileName: string): void {
    this.meetingsService
        .download(meetingId, key)
        .then(file => {
          FileSaver.saveAs(file, fileName);
        });
  }

}
