import {
  IMember,
  IMemberLookup,
  IMembership,
  IMembershipPayment,
  IMemberPost,
  IPerson
} from '@app/lib/interfaces';

export class MembershipsState {
  membershipPayment?: IMembershipPayment = null;
  memberPostsData?: IMemberPost[] = null;
  members?: IMember[] = null;
  memberLookups?: IMemberLookup[] = null;
  paid?: boolean = null;
  invoiced?: boolean = null;
  registeredBy?: IPerson = null;
  selectedMembership?: IMembership = null;
  isRenewal?: boolean = null;
}
