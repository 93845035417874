import { Injectable } from '@angular/core';

import { CareersState } from '@app/careers/careers.state';
import { Store } from '@app/lib/state/store';

@Injectable()
export class CareersStore extends Store<CareersState> {

  constructor () {
    super(new CareersState());
  }

}
