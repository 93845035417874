<ng-container [formGroup]="form">
  <fieldset formArrayName="registrants" id="registrantsFieldset">
    <legend>Please enter the email address of each attendee<ng-container *ngIf="meeting && meeting.location && meeting.virtual"> and indicate if they will be attending in person or virtually</ng-container>.</legend>
    <div class="row" *ngFor="let registrant of form.controls.registrants['controls']; let i = index;" [formGroupName]="i">
      <div class="col-sm-4 form-group">
        <input #email type="email" class="form-control" [placeholder]="(i + 1 | ordinal) + ' Attendee Email'" aria-label="Registrant's email" formControlName="email">
        <form-errors [validationMessages]="validationMessages" [control]="registrant.controls.email" [validationMessagePath]="'email'"></form-errors>
      </div>
      <div class="col-sm-3 form-group" *ngIf="meeting && meeting.location && meeting.virtual">
        <div class="mt-2">
          <div class="custom-control custom-radio">
            <input type="radio" [attr.id]="'virtual-false-' + i" formControlName="virtual" class="custom-control-input" [value]="false">
            <label class="custom-control-label" [attr.for]="'virtual-false-' + i">In Person</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" [attr.id]="'virtual-true-' + i" formControlName="virtual" class="custom-control-input" [value]="true">
            <label class="custom-control-label" [attr.for]="'virtual-true-' + i">Virtual</label>
          </div>
        </div>
        <form-errors [validationMessages]="validationMessages" [control]="registrant.controls.virtual" [validationMessagePath]="'virtual'"></form-errors>
      </div>
      <div *ngIf="i > 1" class="col-sm-1 px-0">
        <button type="button" class="btn p-0 pt-1" (click)="removeRegistrant(i)" style="min-width: auto;">
          <i class="material-icons" title="Remove registrant">highlight_off</i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-sm btn-secondary" (click)="addRegistrant()">Add Attendee</button>
      </div>
    </div>
  </fieldset>

  <registered-by-form [form]="registeredByForm"></registered-by-form>

  <fieldset class="actions">
    <button type="button" class="btn btn-primary btn-lg" [disabled]="!form.valid || form.controls.registrants['controls'].length < 2 || !registeredByForm.valid" (click)="next()">Next</button>
  </fieldset>
</ng-container>
