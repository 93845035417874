import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { asyncScheduler } from 'rxjs';
import { flatten } from 'flat';

import { AuthService } from '@app/auth/auth.service';
import { regexPatterns } from '@app/lib/regex-patterns';

@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChild('emailControl')
  emailControl: ElementRef;

  form: UntypedFormGroup;
  processing = false;
  showError = false;
  validationMessages = flatten({
    email: {
      required: 'This field is required',
      pattern: 'Must be a valid email address',
    },
    password: {
      required: 'This field is required',
    }
  });

  constructor (
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [
        '', [
          Validators.required, Validators.pattern(regexPatterns.email),
        ]
      ],
      password: [
        '', [
          Validators.required,
        ]
      ],
    });
  }

  ngAfterViewInit(): void {
    asyncScheduler.schedule(() => {
      this.emailControl.nativeElement.focus();
    });
  }

  onSubmit(): void {
    this.processing = true;
    this.showError = false;

    this.authService
        .login(this.form.value.email, this.form.value.password)
        .catch(() => this.showError = true)
        .then(() => this.router.navigate(['/attendance']))
        .finally(() => this.processing = false);
  }

}
