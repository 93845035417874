import { Injectable } from '@angular/core';

import { MembershipsState } from '@app/memberships/memberships.state';
import { Store } from '@app/lib/state/store';

@Injectable()
export class MembershipsStore extends Store<MembershipsState> {

  constructor () {
    super(new MembershipsState());
  }

  clearState(): void {
    this.setState(new MembershipsState());
  }
}
