import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { IPaymentMethod } from '@app/lib/interfaces';
import { INVOICE, PAYMENT_METHODS } from '@app/lib/data/payment-methods';

@Component({
  selector: 'payment-method-form',
  template: `
    <fieldset *ngIf="form" [formGroup]="form">
      <div class="row">
        <div class="col form-group">
          <legend>How would you like to pay?</legend>
          <div class="custom-control custom-radio" *ngFor="let paymentMethod of paymentMethods; let i = index;">
            <input formControlName="paymentMethod" [value]="paymentMethod.value" type="radio" [id]="'paymentMethod-' + i" name="paymentMethod" class="custom-control-input">
            <label class="custom-control-label" [attr.for]="'paymentMethod-' + i">{{ paymentMethod.description }}</label>
          </div>
        </div>
      </div>
    </fieldset>
  `
})
export class PaymentMethodFormComponent implements OnInit, OnDestroy {

  @Input()
  set excludeInvoice(value: boolean) {
    if (value) {
      this.paymentMethods = this.paymentMethods.filter(pm => pm.value !== INVOICE);
    }
    else {
      this.paymentMethods = PAYMENT_METHODS;
    }
  }

  @Input()
  form: UntypedFormGroup = null;

  @Output()
  paymentMethodSelected = new EventEmitter<string>();

  paymentMethods: IPaymentMethod[] = PAYMENT_METHODS;
  subscription: Subscription;

  ngOnInit(): void {
    if (this.form) {
      this.form.addControl('paymentMethod', new UntypedFormControl('', [ Validators.required ]));

      this.paymentMethodChangeHandler();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  paymentMethodChangeHandler(): void {
    const control = <UntypedFormControl>this.form.get('paymentMethod');

    this.subscription = control.valueChanges.subscribe(value => {
      this.paymentMethodSelected.emit(value);
    });
  }

}
