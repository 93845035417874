import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { ActivityComponent } from '@app/lib/components/activity/activity.component';
import { BooleanToStringPipe } from '@app/lib/pipes/boolean-to-string.pipe';
import { FormErrorsComponent } from '@app/lib/components/forms/form-errors.component';
import { GuestRegistrationFormComponent } from '@app/lib/components/forms/guest-registration-form/guest-registration-form.component';
import {
  GoToPageComponent,
  PaginationComponent,
  PaginationLinksComponent,
  PerPageComponent,
} from '@app/lib/components/pagination';
import { InvoiceFormComponent } from '@app/lib/components/forms/invoice/invoice-form.component';
import { ListToSentencePipe } from '@app/lib/pipes/list-to-sentence.pipe';
import { TimePlaceComponent } from '@app/lib/components/time-place/time-place.component';
import { MembershipPipe } from '@app/lib/pipes/membership.pipe';
import { NumberOfPeopleComponent } from '@app/lib/components/forms/number-of-people/number-of-people.component';
import { PaymentMethodFormComponent } from '@app/lib/components/forms/payment-method/payment-method-form.component';
import { PersonFormComponent } from '@app/lib/components/forms/person/person-form.component';
import { QuestionsComponent } from '@app/lib/components/questions/questions.component';
import { MemberOptionsFormComponent } from '@app/lib/components/forms/member-options-form/member-options-form.component';
import { MembershipPriceComponent } from '@app/lib/components/memberships/price/membership-price.component';
import { MembershipsValidators } from '@app/memberships/memberships.validators';
import { MobileDateTimePipe } from './../pipes/mobile-date-time.pipe';
import { NewlineToBreakPipe } from '@app/lib/pipes/newline-to-break.pipe';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxPaginationModule } from 'ngx-pagination';
import { PayableToComponent } from '@app/lib/components/forms/payable-to.component';
import { PluralizePipe } from '@app/lib/pipes/pluralize.pipe';
import { OrdinalPipe } from '@app/lib/pipes/ordinal.pipe';
import { RecaptchaFormComponent } from './../components/forms/recaptcha/recaptcha-form.component';
import { RegisteredByFormComponent } from '@app/lib/components/forms/registered-by/registered-by-form.component';
import { RegistrationFeesComponent } from '@app/lib/components/registration-fees/registration-fees.component';
import { RegistrationTypeFormComponent } from '@app/lib/components/forms/registration-type-form/registration-type-form.component';
import { SpeakerComponent } from '@app/lib/components/speaker/speaker.component';
import { StudentInfoFormComponent } from '@app/lib/components/forms/student-info-form/student-info-form.component';
import { TruncateTextComponent } from '@app/lib/components/truncate-text/truncate-text.component';
import { UnconfirmedComponent } from '@app/meetings/components/unconfirmed/unconfirmed.component';

const declarations: any[] = [
  ActivityComponent,
  BooleanToStringPipe,
  FormErrorsComponent,
  GoToPageComponent,
  GuestRegistrationFormComponent,
  InvoiceFormComponent,
  ListToSentencePipe,
  TimePlaceComponent,
  MemberOptionsFormComponent,
  MembershipPipe,
  MembershipPriceComponent,
  MobileDateTimePipe,
  NewlineToBreakPipe,
  NumberOfPeopleComponent,
  PaginationComponent,
  PaginationLinksComponent,
  PerPageComponent,
  PayableToComponent,
  PaymentMethodFormComponent,
  PersonFormComponent,
  PluralizePipe,
  OrdinalPipe,
  QuestionsComponent,
  RecaptchaFormComponent,
  RegisteredByFormComponent,
  RegistrationFeesComponent,
  RegistrationTypeFormComponent,
  SpeakerComponent,
  StudentInfoFormComponent,
  TruncateTextComponent,
  UnconfirmedComponent,
];

const modules: any[] = [
  CommonModule,
  FormsModule,
  NgxCaptchaModule,
  NgxMaskDirective,
  NgxPaginationModule,
  ReactiveFormsModule,
];


@NgModule({
  declarations: [
    ...declarations,
  ],
  imports: [
    ...modules,
  ],
  exports: [
    ...modules,
    ...declarations,
  ],
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        MembershipsValidators,
        provideNgxMask(),
      ]
    };
  }
}
