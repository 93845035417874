import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import flatten from 'flat';
import { IMemberLookup, IRegistration } from '@app/lib/interfaces';
import { RegistrationsService } from '@app/registrations/registrations.service';

@Component({
  templateUrl: './non-member-registration-form.component.html',
})
export class NonMemberRegistrationFormComponent implements OnInit, OnDestroy, AfterContentChecked {

  form: UntypedFormGroup;
  meetingId: string;
  memberLookups: IMemberLookup[] = null;
  registrationTypeWasSelected = false;
  showStudentForm = false;
  subscription: Subscription;
  validationMessages = flatten({});

  constructor (
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private registrationsService: RegistrationsService,
    private router: Router,
  ) {}

  ngAfterContentChecked(): void {
    // detect changes caused by the instantiation of components that modify
    // properties of this component. Prevents "ExpressionChangedAfterItHasBeenCheckedError"
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.subscription = this.registrationsService.state$.pipe(take(1)).subscribe(state => {
      this.memberLookups = state.memberLookups;

      if (!this.memberLookups) {
        this.router.navigate(['/']);
      }

      if (this.memberLookups) {
        this.form = this.formBuilder.group({});
        this.meetingId = state.selectedMeeting.id;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onRegistrationTypeSelected(value: boolean): void {
    this.registrationTypeWasSelected = value;
  }

  onNext(): void {
    const registration: IRegistration = Object.assign(this.form.value, {
      email: this.memberLookups[0].email,
      meeting: this.meetingId,
      virtual: this.memberLookups[0].virtual,
    });

    this.registrationsService.setState({
      memberLookups: null,
      registrations: [registration],
    });

    this.router.navigate(['/registration/payment']);
  }

}
