import { AbstractControl, UntypedFormArray, ValidatorFn } from '@angular/forms';

import { groupObjectsBy } from '@app/lib/utils';

export function checkDuplicates(controlName: string): ValidatorFn {
  return (formArrayControl: UntypedFormArray): null => {

    const controls: AbstractControl[] = [];

    formArrayControl.controls.forEach(control => {
      controls.push(control.get(controlName));
    });

    const valuesGroup = groupObjectsBy(controls, 'value');
    const errorKeys: string[] = [];

    Object.keys(valuesGroup).forEach(key => {
      if (valuesGroup[key].length > 1) {
        errorKeys.push(key);
      }
    });

    controls.forEach(control => {
      let errors = control.errors;

      if (errorKeys.includes(control.value)) {
        errors = errors || {};
        Object.assign(errors, { duplicate: true });
      }
      else {
        if (errors && errors.hasOwnProperty('duplicate')) {
          delete errors['duplicate'];

          if (Object.keys(errors).length === 0) {
            errors = null;
          }
        }
      }

      control.setErrors(errors);
    });

    return null;
  };
}
