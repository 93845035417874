import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IMeeting } from '@app/lib/interfaces';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'meeting',
  templateUrl: './meeting.component.html',
})
export class MeetingComponent {

  @Input()
  meeting: IMeeting;

}
