import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { getEnvironmentValues } from '@app/environments/utils';
import { IMemberLookup, IMember } from '@app/lib/interfaces';

@Injectable()
export class MembersService {

  baseUrl = `${ getEnvironmentValues().api.url }/members`;

  constructor (
    private httpClient: HttpClient,
  ) {}

  lookupMember(email: string) {
    return this.httpClient.get<IMember>(`${ this.baseUrl }/lookupMember?email=${ email }`);
  }

  async lookupMembers(emails: string | string[]): Promise<IMemberLookup[]> {
    const emailAddresses = typeof emails === 'string' ? [emails] : emails;

    return this.httpClient
                .get<IMemberLookup[]>(`${ this.baseUrl }/lookup?emails=${ emailAddresses.join(',') }`)
                .toPromise();
  }

}
