import { Component, Input } from '@angular/core';

import { IMeeting } from '@app/lib/interfaces';

@Component({
  selector: 'payable-to',
  template: `
    <p>Don&#8217;t forget to bring your payment with you to the next meeting if paying with cash, or mail your check to:</p>
    <ul class="list-unstyled">
      <li><strong>TulsaHRC</strong></li>
      <li>11633 E 86th St N</li>
      <li>Owasso, OK 74055-2532</li>
    </ul>
    <p>Please make checks payable to: &#8220;Tulsa HRC&#8221;. <ng-container *ngIf="meeting">We must receive payment on or before {{ meeting.startDateTime }} to confirm your registration.</ng-container></p>
  `
})
export class PayableToComponent {

  @Input()
  meeting: IMeeting = null;

}
