<div class="row">
  <div class="col-sm-9 col-md-9 col-lg-9">
    <div *ngIf="meeting && registrations; else loading;">
      <h1>Thanks {{ registrantFirstName }}!</h1>

      <ng-container *ngIf="registrations.length === 1">
        <h3>Your registration for &#8220;{{ meeting.title }}&#8221; is <ng-container *ngIf="(amountPaid === 0 || paid || fullyPaidMember || meeting.noCost) && !invoiced">confirmed</ng-container><ng-container *ngIf="(!fullyPaidMember && !paid && !meeting.noCost && amountPaid > 0) || invoiced">pending</ng-container>.</h3>

        <p *ngIf="paid || fullyPaidMember">You should receive your check-in code via email at <strong>{{ registrationEmail }}</strong> shortly. Be sure to bring your phone or a printout with you to receive your certification.</p>

        <p *ngIf="(!fullyPaidMember && !paid) || invoiced">Please be sure to bring your payment with you to the meeting.</p>
      </ng-container>
      <ng-container *ngIf="registrations.length > 1">
        <h3>Your registrations are {{ (amountPaid === 0 || paid || meeting.noCost) ? 'confirmed' : 'pending' }} for:</h3>
        <ul>
          <li *ngFor="let registration of registrations">{{ registration.firstName }} {{ registration.lastName }} - {{ registration.email }}</li>
        </ul>
        <p *ngIf="!paid && amountPaid !== 0 && !meeting.noCost">Please be sure to bring your payment with you to the meeting.</p>
      </ng-container>
      <a class="btn btn-secondary btn-lg" routerLink="/">Return to Meetings</a>
    </div>
  </div>
</div>

<ng-template #loading>
  <activity></activity>
</ng-template>
