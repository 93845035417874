import { Component, Input, Output, ViewEncapsulation } from '@angular/core';
import { getEnvironmentValues } from '@app/environments/utils';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'per-page',
  template: `
    <div class="form-group">
      <label>Per Page</label>
      <select class="form-control" (change)="onPerPageChange($event.target.value)">
        <option *ngFor="let choice of itemsPerPageChoices">{{ choice }}</option>
      </select>
    </div>
  `
})
export class PerPageComponent {
  itemsPerPageChoices: number[] = getEnvironmentValues().pagination.itemsPerPageChoices;

  @Input()
  paginationConfig: any;

  onPerPageChange(perPage: number) {
    this.paginationConfig.itemsPerPage = perPage;
    this.paginationConfig.currentPage = 1;
  }
}
