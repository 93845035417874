import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { getEnvironmentValues } from '@app/environments/utils';
import {
  ICreateRegistrationsPostData,
  IEmailSentResponse,
  IRegistration,
  IRegistrationCheck,
} from '@app/lib/interfaces';
import { RegistrationsState } from '@app/registrations/registrations.state';
import { RegistrationsStore } from '@app/registrations/registrations.store';

@Injectable()
export class RegistrationsService {

  baseUrl = `${ getEnvironmentValues().api.url }/registrations`;
  state$: Observable<RegistrationsState>;

  constructor(
    private httpClient: HttpClient,
    private store: RegistrationsStore,
  ) {
    this.state$ = this.store.state$;
  }

  // check for duplicate registrations and return member details if present
  checkRegistrations(email?: string) {
    const emailQueryString = email ? `&email=${ email }` : '';

    let meetingId;
    this.state$.pipe(take(1)).subscribe(state => meetingId = state.selectedMeeting.id);

    return this.httpClient.get<IRegistrationCheck>(`${ this.baseUrl }/lookup?meeting=${ meetingId }${ emailQueryString }`);
  }

  async createRegistrations(createRegistrationsPostData: ICreateRegistrationsPostData): Promise<IRegistration[]> {
    const paid = createRegistrationsPostData.stripeChargeData !== undefined;
    const invoiced = createRegistrationsPostData.invoiceData !== undefined;

    this.store.setState({ paid, invoiced });

    return this.httpClient
                .post<IRegistration[]>(this.baseUrl, createRegistrationsPostData)
                .pipe(
                  tap(newRegistrations => this.store.setState({ registrations: newRegistrations })),
                )
                .toPromise();
  }

  async sendConfirmationEmail(registrations: IRegistration[]): Promise<IEmailSentResponse> {
    return this.httpClient
                .post<IEmailSentResponse>(`${ this.baseUrl }/send-confirmation-email`, { registrations })
                .toPromise();
  }

  async resendConfirmationEmail(email: string, meetingId: string): Promise<IEmailSentResponse> {
    return this.httpClient
                .get<IEmailSentResponse>(`${ this.baseUrl }/resend-confirmation-email?email=${ email }&meetingId=${ meetingId }`)
                .toPromise();
  }

  async sendPendingEmail(registrations: IRegistration[]): Promise<IEmailSentResponse> {
    return this.httpClient
                .post<IEmailSentResponse>(`${ this.baseUrl }/send-pending-email`, { registrations })
                .toPromise();
  }

  async sendReceipt(registrationIds: string[], meetingId: string, amountDue: number) {
    return this.httpClient
                .post<IEmailSentResponse>(`${ this.baseUrl }/send-receipt`, {
                  registrationIds,
                  meetingId,
                  amountDue,
                })
                .toPromise();
  }

  setState(values: RegistrationsState): void {
    this.store.setState(values);
  }

  clearState(): void {
    this.store.clearState();
  }
}
