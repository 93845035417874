import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { getEnvironmentValues } from '@app/environments/utils';
import { IOfficer } from '@app/lib/interfaces';

@Injectable()
export class AboutService {

  baseUrl = `${ getEnvironmentValues().api.url }/about`;

  constructor (
    private httpClient: HttpClient,
  ) {}

  getOfficers(): Promise<IOfficer[]> {
    return this.httpClient.get<IOfficer[]>(`${ this.baseUrl }/officers`).toPromise();
  }

}
