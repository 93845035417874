import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { PRO_RATED_COST } from '@app/lib/data/constants';
import { getCostKey } from '@app/lib/utils';
import { IMembership } from '@app/lib/interfaces';
import { MetaDataService } from '@app/lib/metadata/metadata.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'membership-price',
  styleUrls: [
    './membership-price.component.scss',
  ],
  template: `
    <div [ngClass]="{ 'cost': true, 'discounted': isProRated }">
      <sup>$</sup>{{ membership.cost.toFixed(2) }}
    </div>
    <div class="pro-rating" *ngIf="isProRated">
      <div><small>Prorated Discount</small></div>
      <div class="discount">- <sup>$</sup>{{ membership.proRatedDiscount.toFixed(2) }}</div>
      <div class="pro-rated-cost"><sup>$</sup>{{ membership.proRatedCost.toFixed(2) }}</div>
    </div>
    `
})
export class MembershipPriceComponent implements OnInit {

  @Input()
  membershipId: string = null;

  @Input()
  yearValid: number = null;

  get membership(): IMembership {
    return this.metaDataService.getMembership(this.membershipId);
  }

  get costKey(): string {
    return getCostKey(this.yearValid);
  }

  currentYear: number;
  isProRated = false;
  PRO_RATED_COST: string = PRO_RATED_COST;

  constructor (
    private metaDataService: MetaDataService,
  ) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.isProRated = this.currentYear === this.yearValid;
  }

}
