import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { INews } from '@app/lib/interfaces';
import { NewsService } from '@app/news/news.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nav-menu',
  styleUrls: [
    './nav-menu.component.scss',
  ],
  template: `
    <menu>
      <ol class="list-unstyled">
        <li>
          <a href="#" routerLink="/memberships" data-dismiss="modal">Become a Member<span>Receive discounted rates on meetings, plus easy access to presentation materials.</span></a>
        </li>
        <li>
          <a href="#" routerLink="/memberships/renew" data-dismiss="modal">Renew Your Membership</a>
        </li>
        <li>
          <a href="#" routerLink="/archived-meetings" data-dismiss="modal">Archived Meetings<span>Access presentation materials and download your certificate of attendance.</span></a>
        </li>
        <li>
          <a href="#" routerLink="/careers" data-dismiss="modal">Careers<span>View and post career opportunities.</span></a>
        </li>
        <li>
          <a href="#" routerLink="/news" data-dismiss="modal">News</a>
          <ul *ngIf="latestNews" class="list-unstyled">
            <li>
              {{ latestNews.content }}
            </li>
            <li>
              <a href="#" routerLink="/news" data-dismiss="modal">All News</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#" routerLink="/member-services" data-dismiss="modal">Member Services</a>
        </li>
        <li>
          <a href="#" routerLink="/about" data-dismiss="modal">About Tulsa HRC</a>
        </li>
        <li *ngIf="homeLinkEnabled">
          <a href="#" (click)="goHome($event)">Home</a>
        </li>
      </ol>
    </menu>
  `
})
export class NavMenuComponent implements OnInit, OnDestroy {

  homeLinkEnabled = false;
  latestNews: INews;
  newsSubscription: Subscription;
  routerSubscription: Subscription;

  constructor (
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly newsService: NewsService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.homeLinkEnabled = event.url !== '/';
      }

      this.changeDetectorRef.markForCheck();
    });

    this.newsSubscription = this.newsService.state$.subscribe(state => {
      this.latestNews = state.latestNews;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.newsSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  goHome(event: Event): void {
    event.preventDefault();

    (jQuery('#menuModal') as any).modal('hide');
    this.router.navigate(['/']);
  }

}
