<meeting-wrapper>
  <ng-container *ngIf="meeting; else loading;">
    <h1>Meeting Registration</h1>
    <h2>for: {{ meeting.title }}</h2>
    <time><i class="fa fa-clock-o"></i> {{ meeting.formattedTime }}</time>
    <registration-form [meeting]="meeting"></registration-form>
  </ng-container>
</meeting-wrapper>

<ng-template #loading>
  <activity></activity>
</ng-template>
