import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { IMeeting } from '@app/lib/interfaces';
import { MeetingsService } from '@app/meetings/meetings.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {

  loadMeetingsError = false;
  loading = false;
  pinnedMeetings: IMeeting[] = null;
  subscription: Subscription;
  upcomingMeetings: IMeeting[] = [];

  constructor (
    private meetingsService: MeetingsService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.meetingsService.state$.subscribe(state => {
      if (!state.upcomingMeetings) {
        this.loading = true;

        this.meetingsService
            .getMeetings()
            .catch(() => this.loadMeetingsError = true)
            .finally(() => this.loading = false);
      }
      else {
        this.pinnedMeetings = state.pinnedMeetings;
        this.upcomingMeetings = state.upcomingMeetings;
      }
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
