import { AbstractControl, ValidatorFn } from '@angular/forms';
import { US_AREA_CODES } from '../data/us-area-codes';

export function areaCodeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control) {
      return null;
    }

    const validAreaCode = US_AREA_CODES.includes(parseInt(control.value.slice(0, 3), 10));

    return validAreaCode ? null : { 'invalidAreaCode': { value: control.value } };
  };
}
