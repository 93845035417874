import { Component, Input } from '@angular/core';

import { IMeeting } from '@app/lib/interfaces';

@Component({
  selector: 'meetings-list',
  styleUrls: [
    './meetings-list.component.scss',
  ],
  templateUrl: './meetings-list.component.html',
})
export class MeetingsListComponent {

  @Input()
  meetings: IMeeting[] = null;

}
