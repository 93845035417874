import { Component, Input } from '@angular/core';

import { IMeeting } from '@app/lib/interfaces';

@Component({
  selector: 'time-place',
  styleUrls: [
    './time-place.component.scss',
  ],
  templateUrl: './time-place.component.html',
})
export class TimePlaceComponent {

  @Input()
  meeting: IMeeting;
}
