<nav>
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link" (click)="paginationApi.previous()" aria-label="Previous" *ngIf="!paginationApi.isFirstPage()">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li *ngFor="let page of paginationApi.pages" class="{{ paginationApi.getCurrent() === page.value ? 'active' : '' }} page-item hide-for-small-only">
      <a [ngClass]="{'page-link': true, 'first': paginationApi.isFirstPage(), 'last': paginationApi.isLastPage()}" (click)="paginationApi.setCurrent(page.value)" [attr.disabled]="paginationApi.getCurrent() == page.value">{{ page.label }}</a>
    </li>
    <li class="page-item">
      <a class="page-link" (click)="paginationApi.next()" aria-label="Next" *ngIf="!paginationApi.isLastPage()">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
</nav>
