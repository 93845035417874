import { ChangeDetectorRef, OnDestroy, ViewChild, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { asyncScheduler, Subscription } from 'rxjs';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';

import { IInvoice, IStripeChargeData } from '@app/lib/interfaces';
import { ONLINE, CHECK_CASH, INVOICE } from '@app/lib/data/payment-methods';
import { PluralizePipe } from '@app/lib/pipes/pluralize.pipe';

@Directive()
export class PaymentBase implements OnDestroy {

  @ViewChild(StripeCardComponent)
  card: StripeCardComponent;

  cardErrorMessage = null;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '19px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#790E1D',
        iconColor: '#790E1D'
      }
    }
  };
  checkCash: string = CHECK_CASH;
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  error = false;
  form: UntypedFormGroup;
  invoice: string = INVOICE;
  online: string = ONLINE;
  paymentMethod: string;
  pluralizePipe = new PluralizePipe();
  processing = false;
  subscription: Subscription;
  validationMessages = {};

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected stripeService: StripeService,
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPaymentMethodSelected(paymentMethod: string): void {
    this.paymentMethod = paymentMethod;

    asyncScheduler.schedule(() => {
      const invoiceFormGroup = this.form.get('invoice');

      if (this.paymentMethod === INVOICE) {
        invoiceFormGroup.enable();
      }
      else {
        if (invoiceFormGroup) {
          invoiceFormGroup.disable();
        }
      }
    }, 100);
  }

  async getStripeToken(card: StripeCardComponent): Promise<any> {
    this.cardErrorMessage = null;

    return new Promise((resolve, reject) => {
      this.stripeService.createToken(card.element, {}).subscribe(result => {
        if (result.error) {
          this.cardErrorMessage = result.error.message;
          reject(result.error);
        }
        else {
          resolve(result.token.id);
        }
      });
    });
  }

  createStripeChargeData(amount: number, token: string, description: string): IStripeChargeData {
    return {
      amount: Math.round((amount + 0.00001) * 100) , // dollars to cents, rounding to the nearest decimal
      source: token,
      currency: 'usd',
      description,
    };
  }

  createInvoiceData(amount: number): IInvoice {
    return Object.assign(this.form.value.invoice, { amount, });
  }

}
