import { Routes } from '@angular/router';

import { AttendeesComponent } from '@app/registrations/components/attendees/attendees.component';
import { ArchivedMeetingsComponent } from '@app/meetings/components/archived-meetings/archived-meetings.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from '@app/auth/components/login/login.component';
import { MeetingDetailComponent } from '@app/meetings/components/meeting-detail/meeting-detail.component';
import { NonMemberRegistrationFormComponent } from '@app/registrations/components/non-member/non-member-registration-form.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OopsComponent } from './oops/oops.component';
import { RegisterComponent } from '@app/registrations/components/register/register.component';
import { RegistrationCompleteComponent } from '@app/registrations/components/complete/registration-complete.component';
import { RegistrationPaymentFormComponent } from '@app/registrations/components/payment/registration-payment-form.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'meetings/:slug',
    component: MeetingDetailComponent,
  },
  {
    path: 'archived-meetings',
    component: ArchivedMeetingsComponent,
  },
  {
    path: 'registration/attendees',
    component: AttendeesComponent,
  },
  {
    path: 'registration/complete',
    component: RegistrationCompleteComponent,
  },
  {
    path: 'registration/payment',
    component: RegistrationPaymentFormComponent,
  },
  {
    path: 'registration/guest-other',
    component: NonMemberRegistrationFormComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(mod => mod.AboutModule),
  },
  {
    path: 'memberships',
    loadChildren: () => import('./memberships/memberships.module').then(mod => mod.MembershipsModule),
  },
  {
    path: 'careers',
    loadChildren: () => import('./careers/careers.module').then(mod => mod.CareersModule),
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then(mod => mod.NewsModule),
  },
  {
    path: 'member-services',
    loadChildren: () => import('./member-services/member-services.module').then(mod => mod.MemberServicesModule),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'attendance',
    loadChildren: () => import('./attendance/attendance.module').then(mod => mod.AttendanceModule),
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'oops',
    component: OopsComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];
