<div class="row" *ngIf="!loading && !loadMeetingsError && pinnedMeetings.length > 0">
  <div class="col col-sm-8 col-md-8 col-lg-8">
    <h1>Next Event</h1>
    <meetings-list [meetings]="pinnedMeetings"></meetings-list>
  </div>
</div>
<div class="row">
  <div class="col col-sm-8 col-md-8 col-lg-8">
    <h1 *ngIf="!loading && !loadMeetingsError && upcomingMeetings.length > 0 && pinnedMeetings.length === 0" class="d-sm-block">Upcoming Meetings</h1>
    <h2 *ngIf="!loading && !loadMeetingsError && upcomingMeetings.length > 0 && pinnedMeetings.length > 0" class="h1 d-sm-block">Upcoming Meetings</h2>
    <activity *ngIf="loading"></activity>
    <meetings-list [meetings]="upcomingMeetings" *ngIf="!loading && upcomingMeetings.length > 0"></meetings-list>
    <div *ngIf="!loading && loadMeetingsError" class="alert alert-danger fade show" role="alert">
      <strong>We&#8217;re sorry!</strong> Meetings are temporarily unavailable. Please check back later.
    </div>
    <h2 *ngIf="!loading && !loadMeetingsError && upcomingMeetings.length === 0">There aren&#8217;t any scheduled meetings at the moment, but check back with us soon.</h2>
  </div>
</div>
