import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { flatten } from 'flat';
import { asyncScheduler } from 'rxjs';

import { areaCodeValidator } from '@app/lib/validators/area-code.validator';
import { regexPatterns } from '@app/lib/regex-patterns';
import { usStates } from '@app/lib/data/us-states';

@Component({
  selector: 'invoice-form',
  templateUrl: './invoice-form.component.html',
})
export class InvoiceFormComponent implements OnInit {

  @Input()
  amountDue: number;

  @Input()
  form: UntypedFormGroup;

  @Input()
  validationMessages: object;

  @ViewChild('companyName')
  companyName: ElementRef;

  states: string[] = usStates;

  constructor (
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    if (this.form) {

      // necessary to prevent Expression has changed after it was checked error in parent view
      asyncScheduler.schedule(() => {
        this.form.addControl('invoice', this.formBuilder.group({
          attentionTo: [
            '', [
              Validators.required,
            ]
          ],
          companyName: [
            '', [
              Validators.required,
            ]
          ],
          address1: [
            '', [
              Validators.required,
            ]
          ],
          address2: [
            '',
          ],
          city: [
            '', [
              Validators.required,
            ]
          ],
          state: [
            'Oklahoma', [
              Validators.required,
            ]
          ],
          zip: [
            '', [
              Validators.required, Validators.minLength(5), Validators.maxLength(5),
            ]
          ],
          phone: [
            '', [
              Validators.required, areaCodeValidator(),
            ]
          ],
          email: [
            '', [
              Validators.required, Validators.pattern(regexPatterns.email),
            ]
          ]
        }));
      });
    }

    Object.assign(this.validationMessages, flatten({
      invoice: {
        attentionTo: {
          required: 'This field is required',
        },
        companyName: {
          required: 'Company Name is required',
        },
        address1: {
          required: 'Address is required',
        },
        city: {
          required: 'City is required',
        },
        state: {
          required: 'State is required',
        },
        zip: {
          required: 'Zip is required',
          minLength: 'Zip must be 5 digits',
          maxLength: 'Zip must be 5 digits',
        },
        phone: {
          required: 'Phone is required',
          invalidAreaCode: 'Please enter a valid phone number',
        },
        email: {
          required: 'Email is required',
          pattern: 'Please enter a valid email'
        },
      }
    }));

    asyncScheduler.schedule(() => {
      this.companyName.nativeElement.focus();
    });
  }

}
