import { Component, Input, OnInit } from '@angular/core';

import { ContentService } from '@app/lib/services/content.service';

@Component({
  selector: 'tag-line',
  styleUrls: [
    './tag-line.component.scss',
  ],
  template: `
    <blockquote *ngIf="visible" [innerHTML]="content"></blockquote>
  `
})
export class TagLineComponent implements OnInit {

  @Input()
  visible: boolean;

  content: string;

  constructor (
    private readonly contentService: ContentService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.content = (await this.contentService.getContent('home-page')).content;
  }

}
