<h3>Registration Fees</h3>
<table cellpadding="0" cellspacing="0" border="0" class="table table-pricing registration-fees" id="registrationFees">
  <tr>
    <th>Virtual Meeting</th>
    <td class="cost text-right">$0.00</td>
  </tr>
  <tr *ngFor="let membership of memberships">
    <th>{{ membership.title }} Member</th>
    <td class="cost text-right"><sup>$</sup>{{ membership.costPerMeeting + meeting.additionalCost | number:"1.2" }}</td>
  </tr>
  <tr *ngFor="let registrationType of registrationTypes">
    <th>{{ registrationType.title }}</th>
    <td class="cost text-right"><sup>$</sup>{{ registrationType.costPerMeeting | number:"1.2" }}</td>
  </tr>
</table>
