import { environment } from './environment';

import { IEnvironment } from './IEnvironment';

export function getEnvironmentValues(): IEnvironment {
  if (typeof window !== 'undefined') {
    const values = sessionStorage.getItem('environment');

    if (values === null || values.startsWith('{{')) {
      // code is running in development mode or tests. Return settings from local environment file.
      return environment;
    }

    return JSON.parse(values) || {};
  }

  return environment;
}
