import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { GuestRegistrationFormComponent } from '@app/lib/components/forms/guest-registration-form/guest-registration-form.component';
import { ICreateRegistrationsPostData, IMemberLookup, IMeeting, IPerson, IRegistrantInfo, IRegistration } from '@app/lib/interfaces';
import { MembershipsService } from '@app/memberships/memberships.service';
import { MetaDataService } from '@app/lib/metadata/metadata.service';
import { RegistrationsService } from '@app/registrations/registrations.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    '../../../lib/scss/patrons.scss',
  ],
  templateUrl: './attendees.component.html',
})
export class AttendeesComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('guestRegistrationForm')
  guestRegistrationForm: GuestRegistrationFormComponent;

  attendeeNumber: number = null;
  form: UntypedFormGroup = null;
  memberLookups: IMemberLookup[] = null;
  registrationIndex: number = null;
  registrations: IRegistration[];
  meeting: IMeeting = null;
  membershipsSubscription: Subscription;
  notAllHaveMemberships: boolean;
  registeredBy: IPerson = null;
  registrantInfo: IRegistrantInfo[];
  registrationsInitialized = false;
  registrationsSubscription: Subscription;
  registrationTypeSelected = false;
  selectedRegistration: IRegistration = null;
  validationMessages = {};

  constructor (
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private membershipsService: MembershipsService,
    private metaDataService: MetaDataService,
    private registrationsService: RegistrationsService,
    private router: Router,
  ) {}

  get allRegistrationsValid(): boolean {
    return this.registrations.every(registration => (registration.member !== null || registration.registrationType !== undefined));
  }

  async ngOnInit(): Promise<void> {
    this.membershipsSubscription = this.membershipsService.state$.pipe(take(1)).subscribe(state => {
      this.memberLookups = state.memberLookups;

      if (this.memberLookups) {
        this.notAllHaveMemberships = this.memberLookups.some(memberLookup => memberLookup.member === null);
      }
    });

    this.registrationsSubscription = this.registrationsService.state$.pipe(take(1)).subscribe(state => {
      this.meeting = state.selectedMeeting;
      this.registeredBy = state.registeredBy;
      this.registrantInfo = state.registrantInfo;
      this.registrations = state.registrations || [];
    });

    // create registrations from member lookups one time
    if (this.memberLookups?.length && this.meeting && !this.registrationsInitialized) {

      this.memberLookups.forEach(memberLookup => {
        let paymentOwed = false;
        const registrantInfo: IRegistrantInfo = this.registrantInfo.find(info => info.email === memberLookup.email);
        const virtual = registrantInfo?.virtual || false;

        memberLookup.virtual = virtual;

        if (this.meeting.noCost || virtual) {
          paymentOwed = false;
        }
        else {
          paymentOwed = memberLookup.member?.membership.costPerMeeting !== 0;
        }

        this.registrations.push({
          meeting: this.meeting.id,
          firstName: memberLookup.member?.firstName || '',
          lastName: memberLookup.member?.lastName || '',
          email: memberLookup.member?.email || memberLookup.email,
          phone: memberLookup.member?.phone || '',
          companyName: memberLookup.member?.companyName || '',
          jobTitle: memberLookup.member?.jobTitle || '',
          member: memberLookup.member || null,
          membershipType: memberLookup?.member?.membership?.title || '',
          registeredBy: this.registeredBy,
          paymentOwed,
          virtual,
        });
      });

      // check if any members owe a fee. If not, call the registration service, create the registrations
      // and navigate to complete
      if (this.memberLookups.every(memberLookup => memberLookup.member !== null && (memberLookup.virtual || this.meeting.noCost || memberLookup.member.membership.costPerMeeting === 0))) {
        this.registrationsService.setState({ amountPaid: 0, });

        const postData: ICreateRegistrationsPostData = {
          meeting: this.meeting.id,
          registrationsData: this.registrations,
        };

        const registrations = await this.registrationsService.createRegistrations(postData);

        // send receipt to person registering attendees as there is no payment created
        const registrationIds = registrations.map(r => r._id);
        await this.registrationsService.sendReceipt(registrationIds, this.meeting.id, 0);

        // send confirmed/pending emails to attendees
        this.registrationsService.sendConfirmationEmail(registrations).then(() => {
          this.router.navigate(['/registration/complete']);
        });
      }

      this.form = this.formBuilder.group({});

      this.registrationsInitialized = true;
    }
  }

  ngOnDestroy(): void {
    this.membershipsSubscription.unsubscribe();
    this.registrationsSubscription.unsubscribe();
  }

  ngAfterContentChecked(): void {
    // detect changes caused by the instantiation of components that modify
    // properties of this component. Prevents "ExpressionChangedAfterItHasBeenCheckedError"
    this.changeDetectorRef.detectChanges();
  }

  cancel(): void {
    this.membershipsService.clearState();
    this.registrationsService.clearState();
    this.router.navigate(['/']);
  }

  selectRegistration(index: number): void {
    this.attendeeNumber = index + 1;
    this.registrationIndex = index;
    this.selectedRegistration = this.registrations[index];
    this.registrationTypeSelected = true;

    // added try/catch due to an unknown issue that was causing `this.form` to be undefined
    // form works as expected despite catch being invoked
    try {
      this.form.reset(this.selectedRegistration);
    }
    catch (e) {

    }
  }

  removeRegistration(): void {
    this.registrations = this.registrations.splice(this.registrationIndex, 1);

    this.registrationsService.setState({
      registrations: this.registrations,
    });
  }

  updateRegistration(): void {
    const values = this.form.value;
    const registration: IRegistration = this.registrations[this.registrationIndex];
    const registrationData: IRegistration = {
      ...registration,
      firstName: values.firstName,
      lastName: values.lastName,
      institutionName: values.institutionName ? values.institutionName : '',
      phone: values.phone,
      registrationType: values.registrationType,
      registrationTypeObject: this.metaDataService.getRegistrationType(values.registrationType),
      studentId: values.studentId ? values.studentId : '',
      virtual: this.registrantInfo[this.registrationIndex].virtual,
    };

    const registrations = [...this.registrations.slice(0, this.registrationIndex), registrationData, ...this.registrations.slice(this.registrationIndex + 1)];

    this.registrationsService.setState({
      registrations,
    });

    this.registrations = registrations;
  }

  onRegistrationTypeSelected(value: boolean): void {
    this.registrationTypeSelected = value;
  }

  onNext(): void {
    this.registrations.forEach(registration => {

      // remove un-needed property
      delete registration['registrationTypeObject'];

      // add registered by for non-members
      if (!registration.hasOwnProperty('registeredBy')) {
        Object.assign(registration, { registeredBy: this.registeredBy, });
      }
    });

    // clear the member lookups as all data has already been converted to registrants
    this.registrationsService.setState({
      memberLookups: null,
      registrations: this.registrations,
    });

    this.router.navigate(['/registration/payment']);
  }

}
