import { Component } from '@angular/core';

@Component({
  template: `
    <div class="row">
      <div class="col">
        <h1>Sorry, unauthorized access is not allowed.</h1>
      </div>
    </div>
  `
})
export class UnauthorizedComponent {}
