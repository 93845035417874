import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { IMemberBase } from '@app/lib/interfaces';

@Component({
  selector: 'member-options-form',
  styleUrls: [
    './member-options-form.component.scss',
  ],
  templateUrl: './member-options-form.component.html',
})
export class MemberOptionsFormComponent implements OnInit {

  @Input()
  form: UntypedFormGroup;

  @Input()
  memberData: IMemberBase;

  ngOnInit(): void {
    if (this.form) {
      this.form.addControl('committees', new UntypedFormControl(this.memberData ? this.memberData.committees : true));
      this.form.addControl('showInDirectory', new UntypedFormControl(this.memberData ? this.memberData.showInDirectory : true));
    }
  }

}
