import { Pipe, PipeTransform } from '@angular/core';

import { IMembership } from '@app/lib/interfaces';
import { MetaDataService } from '@app/lib/metadata/metadata.service';

@Pipe({
  name: 'membershipValue'
})
export class MembershipPipe implements PipeTransform {

  constructor (
    private metaDataService: MetaDataService,
  ) {}

  transform(id: string, key: string): string|number {
    const membership: IMembership = this.metaDataService.getMembership(id);
    return membership[key];
  }

}
