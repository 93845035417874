import { APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { RouterModule } from '@angular/router';

import { AboutService } from '@app/about/about.service';
import { appRoutes } from './app.routes';
import { AppComponent } from './app.component';
import { AttendeesComponent } from '@app/registrations/components/attendees/attendees.component';
import { AuthService } from '@app/auth/auth.service';
import { AuthStore } from '@app/auth/auth.store';
import { ArchivedMeetingsComponent } from '@app/meetings/components/archived-meetings/archived-meetings.component';
import { CareersService } from '@app/careers/careers.service';
import { CareersStore } from '@app/careers/careers.store';
import { ContentService } from '@app/lib/services/content.service';
import { getEnvironmentValues } from '@app/environments/utils';
import { FileIconPipe } from '@app/lib/pipes/file-icon.pipe';
import { FileSizePipe } from '@app/lib/pipes/file-size.pipe';
import { FooterComponent } from '@app/lib/components/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { InvoicesService } from '@app/lib/services/invoices.service';
import { LoginComponent } from '@app/auth/components/login/login.component';
import { MeetingComponent } from '@app/meetings/components/meeting/meeting.component';
import { MeetingDetailComponent } from '@app/meetings/components/meeting-detail/meeting-detail.component';
import { MeetingWrapperComponent } from '@app/meetings/components/wrapper/meeting-wrapper.component';
import { MeetingsListComponent } from '@app/meetings/components/meetings-list/meetings-list.component';
import { MeetingsService } from '@app/meetings/meetings.service';
import { MeetingsStore } from '@app/meetings/meetings.store';
import { MembersService } from '@app/members/members.service';
import { MembershipsService } from '@app/memberships/memberships.service';
import { MembershipsStore } from '@app/memberships/memberships.store';
import { metadataProviderFactory } from '@app/lib/metadata/metadata.provider';
import { MetaDataService } from '@app/lib/metadata/metadata.service';
import { MetaDataStore } from '@app/lib/metadata/metadata.store';
import { MultiRegistrationComponent } from '@app/registrations/components/multi-registration/multi-registration.component';
import { NavMenuComponent } from '@app/lib/components/nav-menu/nav-menu.component';
import { newsInitializer } from './news/news.provider';
import { NewsService } from '@app/news/news.service';
import { NewsStore } from './news/news.store';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxStripeModule } from 'ngx-stripe';
import { NonMemberRegistrationFormComponent } from '@app/registrations/components/non-member/non-member-registration-form.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OopsComponent } from './oops/oops.component';
import { PaymentsService } from '@app/lib/services/payments.service';
import { RegisterComponent } from '@app/registrations/components/register/register.component';
import { RegisterButtonComponent } from '@app/registrations/components/register-button/register-button.component';
import { RegistrationCompleteComponent } from '@app/registrations/components/complete/registration-complete.component';
import { RegistrationFormComponent } from '@app/registrations/components/registration-form/registration-form.component';
import { RegistrationPaymentFormComponent } from '@app/registrations/components/payment/registration-payment-form.component';
import { RegistrationsService } from '@app/registrations/registrations.service';
import { RegistrationsStore } from '@app/registrations/registrations.store';
import { RegistrationValidators } from '@app/registrations/registration.validators';
import { SharedModule } from '@app/lib/modules/shared.module';
import { SingleRegistrationFormComponent } from '@app/registrations/components/single-registration/single-registration-form.component';
import { TagLineComponent } from '@app/lib/components/tag-line/tag-line.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UserAgentService } from '@app/lib/services/user-agent.service';
import { WINDOW_PROVIDERS } from '@app/lib/services/window.service';

@NgModule({
  declarations: [
    AppComponent,
    AttendeesComponent,
    ArchivedMeetingsComponent,
    FileIconPipe,
    FileSizePipe,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    MeetingComponent,
    MeetingDetailComponent,
    MeetingWrapperComponent,
    MeetingsListComponent,
    MultiRegistrationComponent,
    NavMenuComponent,
    NonMemberRegistrationFormComponent,
    NotFoundComponent,
    OopsComponent,
    RegisterComponent,
    RegisterButtonComponent,
    RegistrationCompleteComponent,
    RegistrationFormComponent,
    RegistrationPaymentFormComponent,
    SingleRegistrationFormComponent,
    TagLineComponent,
    UnauthorizedComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxCaptchaModule,
    NgxStripeModule.forRoot(getEnvironmentValues().stripe.key),
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top' }),
    SharedModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: metadataProviderFactory,
      deps: [
        MetaDataService,
      ],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: newsInitializer,
      deps: [
        NewsService,
      ],
      multi: true,
    },
    AboutService,
    AuthService,
    AuthStore,
    CareersService,
    CareersStore,
    ContentService,
    InvoicesService,
    MembersService,
    MembershipsService,
    MembershipsStore,
    MeetingsService,
    MeetingsStore,
    MetaDataService,
    MetaDataStore,
    NewsService,
    NewsStore,
    PaymentsService,
    provideEnvironmentNgxMask(),
    RegistrationsService,
    RegistrationsStore,
    RegistrationValidators,
    UserAgentService,
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
