import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IContact } from '@app/lib/interfaces';
import { MetaDataService } from '@app/lib/metadata/metadata.service';

@Component({
  selector: 'app-footer',
  styleUrls: [
    './footer.component.scss',
  ],
  template: `
    <address>
      <strong>TulsaHRC</strong>
      Tulsa, Oklahoma 74101
    </address>
    <p *ngIf="primaryContact">
      <strong>Contact</strong>
      {{ primaryContact.name.first }} {{ primaryContact.name.last }}<ng-container *ngIf="primaryContact.title">, {{ primaryContact.title }}</ng-container>
      <br /><a class="btn btn-link" [href]="'mailto:' + primaryContact.email">{{ primaryContact.email }}</a>
    </p>
  `
})
export class FooterComponent implements OnInit, OnDestroy {

  primaryContact: IContact = null;
  subscription: Subscription;

  constructor (
    private metaDataService: MetaDataService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.metaDataService.state$.subscribe(state => {
      this.primaryContact = state.primaryContact;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
